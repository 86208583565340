import { CheckboxFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<CheckboxFieldProps> = {
	schemaType: "formComponent",
	component: "CheckboxField",
	displayName: "Checkbox Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{
					type: "TextField",
					title: "Value",
					key: "value",
					mandatory: true,
					helptext: "Value to send to CRM if checked",
				},
				{ ...mandatory },
				{
					type: "UniqueCheck",
					title: "Do not send",
					key: "notSendCrm",
					options: [
						{
							title: "Do not send this field to CRM.",
						},
					],
				},
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "CheckboxField",
		value: "true",
		fieldWidth: "100",
	},
};

export default schema;

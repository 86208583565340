import { AGREEMENTS_TYPE } from "./AGREEMENTS_TYPE";
import { EVENT_CATEGORY } from "./EVENT_CATEGORY";
import { EVENT_TYPE } from "./EVENT_TYPE";
import { FORMAT } from "./FORMAT";
import { KNOWLEDGE_AREA } from "./KNOWLEDGE_AREA";
import { LOCATION } from "./LOCATION";
import { MODALITY } from "./MODALITY";
import { NEWS_TYPE } from "./NEWS_TYPE";
import { PEOPLE_TAG } from "./PEOPLE_TAG";
import { PROFILE } from "./PROFILE";
import { PROJECT_KEYWORD } from "./PROJECT_KEYWORD";
import { REGION } from "./REGION";
import { REGISTRATION } from "./REGISTRATION";
import { RESEARCH_AREA } from "./RESEARCH_AREA";
import { SERVICE } from "./SERVICE";
import { TOPIC } from "./TOPIC";
import { VIDEO_CAPTION } from "./VIDEO_CAPTION";
import { VIDEO_CREATION } from "./VIDEO_CREATION";
import { VIDEO_CREATOR } from "./VIDEO_CREATOR";
import { VIDEO_DURATION } from "./VIDEO_DURATION";
import { VIDEO_TAG } from "./VIDEO_TAG";
import { VIDEO_UPDATE } from "./VIDEO_UPDATE";

export default {
	AGREEMENTS_TYPE,
	EVENT_CATEGORY,
	EVENT_TYPE,
	FORMAT,
	KNOWLEDGE_AREA,
	LOCATION,
	MODALITY,
	NEWS_TYPE,
	PEOPLE_TAG,
	PROFILE,
	PROJECT_KEYWORD,
	REGION,
	RESEARCH_AREA,
	REGISTRATION,
	SERVICE,
	TOPIC,
	VIDEO_CAPTION,
	VIDEO_CREATION,
	VIDEO_CREATOR,
	VIDEO_DURATION,
	VIDEO_TAG,
	VIDEO_UPDATE,
};

import { ProgramSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<ProgramSelectorProps> = {
	schemaType: "formComponent",
	component: "ProgramSelector",
	displayName: "Program Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "UniqueCheck",
					title: "Filter by academic area",
					key: "filterByArea",
					options: [
						{
							title: "If checked, an academic area selector will be added.",
						},
					],
				},
				{
					type: "ConditionalField",
					title: "Selection type",
					key: "selectionType",
					options: [
						{ name: "checkbox", value: "checkbox", title: "Multiple" },
						{ name: "text", value: "text", title: "Single" },
					],
					fields: [
						{
							condition: "checkbox",
							...crmKey,
							title: "CRM key for program 1",
							key: "crmKeyProgram1",
						},
						{
							condition: "checkbox",
							...crmKey,
							title: "CRM key for program 2",
							key: "crmKeyProgram2",
						},
						{
							condition: "checkbox",
							...crmKey,
							title: "CRM key for program 3",
							key: "crmKeyProgram3",
						},
						{
							condition: "checkbox",
							type: "TextField",
							title: "CRM key for programs in Postgraduate Event Form",
							key: "crmKeyPostgraduateEvents",
							helptext:
								"For example: com_programasdeinteres. Only applies in Postgraduate Event Form when sending contact requests.",
						},
						{
							condition: "text",
							...crmKey,
						},
					],
					mandatory: true,
				},
				{ ...label },
				{
					type: "ReferenceField",
					title: "",
					key: "data",
					sources: [
						{ structuredData: "PROGRAM" },
						{ structuredData: "PROGRAM_EXTERNAL" },
					],
					mandatory: true,
				},
				{ ...mandatory },
			],
		},
	],

	default: {
		component: "ProgramSelector",
		filterByArea: true,
		selectionType: "checkbox",
		crmKeyProgram1: "com_programofintereststring",
		crmKeyProgram2: "com_programainteresado2txt",
		crmKeyProgram3: "com_programainteresado3txt",
		crmKeyPostgraduateEvents: "com_programasdeinteres",
		// hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "PROGRAM" }],
		},
	},
};

export default schema;

import { TikTokCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TikTokCollectionProps> = {
	schemaType: "module",
	component: "TikTokCollection",
	displayName: "TikTok Collection",
	category: "collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, isMockup: true },
				{ ...subtitle, mandatory: true, hideable: false },
				{ ...content, mandatory: true, hideable: false },
				{
					type: "ComponentContainer",
					title: "Main link",
					key: "link1",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Secondary link",
					key: "link2",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "TextField",
					title: "Highlighted TikTok",
					key: "highlighted",
					mandatory: true,
				},
				{
					type: "ArrayFieldGroup",
					title: "TikToks",
					key: "items",
					arrayType: "inline",
					name: "URL",
					fields: [
						{
							type: "TextField",
							title: "TikTok URL",
							key: "url",
							mandatory: true,
						},
					],
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "TikTokCollection",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		link1: { component: "Link" },
		link2: { component: "Link" },
		items: [
			{ url: "" },
			{ url: "" },
			{ url: "" },
			{ url: "" },
			{ url: "" },
			{ url: "" },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TikTokCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TikTokCollection/thumbnail@2x.png",
	},
};

export default schema;

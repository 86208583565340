import { AcademicAreaSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<AcademicAreaSelectorProps> = {
	schemaType: "formComponent",
	component: "AcademicAreaSelector",
	displayName: "Academic Area Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "note",
					value: {
						title: "",
						text: "This field options will be filtered by program type selector, if exists.",
					},
				},
				{
					...crmKey,
					mandatory: false,
					helptext:
						"For example: com_programofinterest. This field is mandatory if there is not a Program Selector field.",
				},
				{ ...label },
				{
					type: "ReferenceField",
					title: "",
					key: "data",
					sources: [{ structuredData: "ACADEMIC_AREA" }],
					mandatory: true,
				},
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "AcademicAreaSelector",
		// hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "ACADEMIC_AREA" }],
		},
		fieldWidth: "100",
	},
};

export default schema;

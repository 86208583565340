import { ProjectsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ProjectsDistributorProps> = {
	schemaType: "module",
	component: "ProjectsDistributor",
	category: "distributors",
	displayName: "Projects Distributor",
	dataPacks: ["PROJECTS"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ConditionalField",
					title: "Intro Type",
					key: "introType",
					mandatory: true,
					options: [
						{
							name: "defaultIntro",
							value: "defaultIntro",
							title: "Default Intro",
						},

						{
							name: "institutionalIntro",
							value: "institutionalIntro",
							title: "Institutional Intro",
						},
					],
					fields: [
						{
							condition: "defaultIntro",
							...additional,
						},
						{
							condition: "defaultIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},

						{
							condition: "defaultIntro",
							...subtitle,
							isMockup: true,
						},
						{
							condition: "defaultIntro",
							...content,
						},
						{
							condition: "institutionalIntro",
							title: "Select Logo",
							type: "Select",
							key: "defaultLogo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{
									value: "logoComillasHorizontal",
									label: "Comillas color",
								},
								{
									value: "logoComillasHorizontalInverse",
									label: "Comillas inverse",
								},
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{
									value: "logoCihsHorizontalInverse",
									label: "CIHS inverse",
								},
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{
									value: "logoEuefHorizontalInverse",
									label: "EUEF inverse",
								},
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{
									value: "logoIcadeHorizontalInverse",
									label: "ICADE inverse",
								},
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{
									value: "logoIcaiHorizontalInverse",
									label: "ICAI inverse",
								},
								{ value: "logoIneaBn", label: "INEA" },
								{ value: "logoInea", label: "INEA color" },
								{ value: "logoIneaInverse", label: "INEA INVERSE" },
							],
						},
						{
							condition: "institutionalIntro",
							...additional,
						},
						{
							condition: "institutionalIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "institutionalIntro",
							...content,
						},
					],
				},
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "PROJECT" }],
					key: "data",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "ProjectsDistributor",
		introType: "defaultIntro",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: loremIpsumParagraph,
		content: null,
		defaultLogo: "logoComillasHorizontalBn",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "PROJECT" }],
			quantity: 6,
			fullRelations: true,
			fields: ["id", "title", "startDate", "endDate", "extensionDate", "url"],
		},
		link: {
			component: "Link",
		},
		anchorID: null,
		subtheme: "default",
		animation: "none",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProjectsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProjectsDistributor/thumbnail@2x.png",
	},
};

export default schema;

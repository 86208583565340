import { EventsListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";
import { detail, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Template<EventsListProps> = {
	dataPacks: ["EVENTS"],
	schemaType: "template",
	displayName: "Events List",
	component: "EventsList",

	type: { label: "Event", value: "event" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{ ...heading, key: "templateTitle", hideable: false, isMockup: true },
		{ ...detail },
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				// {
				// 	title: "Filter (Date)",
				// 	name: "filterDate",
				// },
				{
					title: "Filter (Type)",
					name: "filterType",
				},
				{
					title: "Filter (Location)",
					name: "filterLocation",
				},
			],
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "",
			key: "data",
			sources: [{ structuredData: "EVENT" }, { structuredData: "EVENT_AUTO" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 6,
			mandatory: true,
		},
		{
			type: "UniqueCheck",
			title: "Show past events",
			key: "pastEvents",
			options: [
				{
					title: "Show past events",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"TikTokCollection",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "EventsList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroInner.default }],
		},
		templateTitle: { content: "Lorem ipsum", tag: "h1" },
		detail: loremIpsumParagraph,
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		itemsPerPage: 9,
		pastEvents: false,
		activePage: 1,
		data: {
			sources: [{ structuredData: "EVENT" }, { structuredData: "EVENT_AUTO" }],
			mode: "auto",
			order: "recent-DESC",
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventsList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventsList/thumbnail@2x.png",
	},
};

export default schema;

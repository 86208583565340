import { PressReleasesDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ContactInfo from "@schemas/modules/ContactInfo";

const schema: Schema.Template<PressReleasesDetailProps> = {
	schemaType: "template",
	component: "PressReleasesDetail",
	displayName: "Press Releases",
	dataPacks: ["PRESS_RELEASES"],
	type: {
		label: "Press Releases",
		value: "Press Releases",
		mode: "detail",
	},

	content: [
		{
			type: "DateField",
			title: "Date",
			key: "itemDate",
			hideable: true,
		},
		{
			title: "Logo",
			type: "ConditionalField",
			key: "logoOption",
			hideable: true,
			options: [
				{
					value: "defaultLogo",
					title: "Default",
					name: "defaultLogo",
				},
				{
					value: "customLogo",
					title: "Custom",
					name: "customLogo",
				},
			],
			fields: [
				{
					condition: "defaultLogo",
					title: "Select Logo",
					type: "Select",
					key: "defaultLogo",
					placeholder: "Select a logo",
					mandatory: true,
					options: [
						{ value: "logoComillasHorizontalBn", label: "Comillas" },
						{
							value: "logoComillasHorizontal",
							label: "Comillas color",
						},
						{
							value: "logoComillasHorizontalInverse",
							label: "Comillas inverse",
						},
						{ value: "logoCihsHorizontalBn", label: "CIHS" },
						{ value: "logoCihsHorizontal", label: "CIHS color" },
						{
							value: "logoCihsHorizontalInverse",
							label: "CIHS inverse",
						},
						{ value: "logoEuefHorizontalBn", label: "EUEF" },
						{ value: "logoEuefHorizontal", label: "EUEF color" },
						{
							value: "logoEuefHorizontalInverse",
							label: "EUEF inverse",
						},
						{ value: "logoIcadeHorizontalBn", label: "ICADE " },
						{ value: "logoIcadeHorizontal", label: "ICADE color" },
						{
							value: "logoIcadeHorizontalInverse",
							label: "ICADE inverse",
						},
						{ value: "logoIcaiHorizontalBn", label: "ICAI" },
						{ value: "logoIcaiHorizontal", label: "ICAI color" },
						{
							value: "logoIcaiHorizontalInverse",
							label: "ICAI inverse",
						},
						{ value: "logoIneaBn", label: "INEA" },
						{ value: "logoInea", label: "INEA color" },
						{ value: "logoIneaInverse", label: "INEA inverse" },
					],
				},
				{
					condition: "customLogo",
					title: "Image",
					type: "ImageField",
					key: "customLogo",
					mandatory: true,
					helptext: "Size recommendation: 276x90px",
				},
			],
		},
		{
			type: "Wysiwyg",
			title: "Content",
			key: "content",
			hideable: true,
			placeholder: "Type a press release content",
			humanReadable: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"IntroForm",
				"VideoPlaylist",
			],
			contentType: "modules",
		},
	],

	config: [
		{
			type: "RadioGroup",
			key: "titleSize",
			title: "Title Size",
			mandatory: true,
			options: [
				{ name: "m", value: "m", title: "m" },
				{ name: "l", value: "l", title: "l" },
			],
		},
	],

	default: {
		type: "template",
		templateType: "PressReleasesDetail",
		logoOption: null,
		defaultLogo: "logoComillasHorizontalBn",
		customLogo: undefined,
		titleSize: "l",
		content: null,
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...ContactInfo.default, subtheme: "default-alt" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PressReleasesDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PressReleasesDetail/thumbnail@2x.png",
	},
};

export default schema;

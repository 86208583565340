import { UIFormFields } from "@griddo/core";

import { crmKey } from "./crmKey";

export const utmFields: UIFormFields.ArrayFieldGroup = {
	type: "ArrayFieldGroup",
	arrayType: "inline",
	title: "UTM fields",
	key: "utmFields",
	name: "UTM field",
	fields: [
		{
			...crmKey,
			helptext:
				"For example: com_utm_medium, com_utm_source, com_utm_campaign, etc.",
			isTitle: true,
		},
		{
			type: "TextField",
			title: "URL param name",
			key: "paramName",
			mandatory: true,
			helptext: "Usually utm_medium, utm_source, utm_campaign, etc.",
		},
	],
};

export const defaultUtmFields = [
	{ crmKey: "com_utm_medium", paramName: "utm_medium" },
	{ crmKey: "com_utm_source", paramName: "utm_source" },
	{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
];

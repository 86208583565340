import { NewsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	emptyState,
	getThemeOptions,
	heading,
	link,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<NewsDistributorProps> = {
	schemaType: "module",
	component: "NewsDistributor",
	category: "articlesAndEvents",
	displayName: "News Distributor",
	dataPacks: ["NEWS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		NewsDetail: ["relatedContent"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle, isMockup: false },
				{ ...content, isMockup: false },
				{
					...emptyState,
				},
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "NEWS" }],
					key: "data",
					maxItems: 4,
					mandatory: true,
					helptext: "Select up to 4 news articles.",
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/NewsDistributor/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/NewsDistributor/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/NewsDistributor/Layouts/layout3.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "NewsDistributor",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: null,
		emptyState:
			"Parece que por el momento no hay ninguna novedad... ¡Pero seguro que pronto las habrá! Visita nuestro portal de comunicación y prensa para no perderte nada.",
		hasDistributorData: true,
		content: null,
		data: {
			mode: "auto",
			order: "newsDate-DESC",
			sources: [{ structuredData: "NEWS", globalOperator: "OR" }],
			quantity: 3,
			fullRelations: true,
			fields: [
				"title",
				"abstract",
				"centers",
				"schools",
				"services",
				"topics",
				"image",
				"newsDate",
				"url",
			],
		},
		link: {
			component: "Link",
		},
		anchorID: null,
		layout: "layout2",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/NewsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/NewsDistributor/thumbnail@2x.png",
	},
};

export default schema;

import { InnerLogoThemerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LogoThemeCard from "@schemas/components/LogoThemeCard";
import {
	additional,
	animation,
	content,
	subtitle,
	heading,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Module<InnerLogoThemerProps> = {
	schemaType: "module",
	component: "InnerLogoThemer",
	category: "collection",
	displayName: "Logo Themer",
	dataPacks: ["BRAND_SITE"],

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ConditionalField",
					title: "Intro Type",
					key: "introType",
					mandatory: true,
					helptext: "Select institutional intro if you want to place a logo",
					options: [
						{
							name: "defaultIntro",
							value: "defaultIntro",
							title: "Default Intro",
						},
						{
							name: "institutionalIntro",
							value: "institutionalIntro",
							title: "Institutional Intro",
						},
					],
					fields: [
						{
							condition: "defaultIntro",
							...additional,
						},
						{
							condition: "defaultIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "defaultIntro",
							...subtitle,
						},
						{
							condition: "defaultIntro",
							...content,
						},

						{
							condition: "institutionalIntro",
							title: "Select Logo",
							type: "Select",
							key: "logo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{
									value: "logoComillasHorizontal",
									label: "Comillas color",
								},
								{
									value: "logoComillasHorizontalInverse",
									label: "Comillas inverse",
								},
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{
									value: "logoCihsHorizontalInverse",
									label: "CIHS inverse",
								},
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{
									value: "logoEuefHorizontalInverse",
									label: "EUEF inverse",
								},
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{
									value: "logoIcadeHorizontalInverse",
									label: "ICADE inverse",
								},
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{
									value: "logoIcaiHorizontalInverse",
									label: "ICAI inverse",
								},
								{ value: "logoIneaBn", label: "INEA" },
								{ value: "logoInea", label: "INEA color" },
								{ value: "logoIneaInverse", label: "INEA INVERSE" },
							],
						},
						{
							condition: "institutionalIntro",
							...additional,
						},
						{
							condition: "institutionalIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "institutionalIntro",
							...content,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["LogoThemeCard"],
					contentType: "components",
				},
			],
		},

		{
			title: "config",
			fields: [{ ...animation }],
		},
	],

	default: {
		component: "InnerLogoThemer",
		introType: "defaultIntro",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: null,
		logo: "logoComillasHorizontalBn",
		elements: [
			{ ...LogoThemeCard.default },
			{ ...LogoThemeCard.default },
			{ ...LogoThemeCard.default },
		],
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LogoThemer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LogoThemer/thumbnail@2x.png",
	},
};

export default schema;

import { UIFormFields } from "@griddo/core/dist/types/schema-fields/ui-form-fields";

export const requestChannelFields: Array<UIFormFields> = [
	{
		type: "TextField",
		title: "Request Channel CRM key",
		key: "requestyChannelCrmKey",
		helptext: "For example: com_requestchannel, leadsourcecode.",
	},
	{
		type: "TextField",
		title: "Request channel value (degrees)",
		key: "requestChannelDegrees",
	},
	{
		type: "TextField",
		title: "Request channel value (other program types)",
		key: "requestChannelOther",
	},
];

import { SectionTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.FormField<SectionTextProps> = {
	schemaType: "formComponent",
	component: "SectionText",
	displayName: "Section Text",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "Wysiwyg",
					full: false,
					title: "Text",
					key: "text",
					mandatory: true,
					humanReadable: true,
					isMockup: true,
				},
			],
		},
	],

	default: {
		component: "SectionText",
		text: loremIpsumParagraph,
	},
};

export default schema;

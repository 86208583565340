import * as React from "react";

const AdHocForm = React.lazy(() => import("./AdHocForm"));
const CustomAdmissionForm = React.lazy(() => import("./CustomAdmissionForm"));
const CustomBasicForm = React.lazy(() => import("./CustomBasicForm"));
const CustomContactForm = React.lazy(() => import("./CustomContactForm"));
const CustomDownloadForm = React.lazy(() => import("./CustomDownloadForm"));
const CustomLandingForm = React.lazy(() => import("./CustomLandingForm"));
const CustomEventForm = React.lazy(() => import("./CustomEventForm"));
const CustomPostgraduateEventForm = React.lazy(
	() => import("./CustomPostgraduateEventForm")
);

export default {
	AdHocForm,
	CustomAdmissionForm,
	CustomBasicForm,
	CustomContactForm,
	CustomDownloadForm,
	CustomLandingForm,
	CustomEventForm,
	CustomPostgraduateEventForm,
};

import { ProgramComparatorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";
import { detail, heading } from "@schemas/presets";

const schema: Schema.Template<ProgramComparatorProps> = {
	schemaType: "template",
	component: "ProgramComparator",
	displayName: "Programs Comparator",
	dataPacks: ["PROGRAMS"],

	type: { label: "Program", value: "program" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				{
					title: "Filter (Academic areas)",
					name: "filterAreas",
				},
				{
					title: "Filter (Centers)",
					name: "filterCenters",
				},
				{
					title: "Filter (Program types)",
					name: "filterType",
				},
				{
					title: "Filter (Languages)",
					name: "filterLanguages",
				},
			],
		},
		{
			type: "TextField",
			title: "No Results title",
			key: "noResultsTitle",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "",
			key: "data",
			sources: [{ structuredData: "PROGRAM" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 8,
			max: 40,
			mandatory: true,
		},
		{
			type: "TextField",
			key: "bottomCtaText",
			title: "CTA label (bottom)",
			humanReadable: true,
		},
		{
			type: "VisualUniqueSelection",
			key: "bottomCtaVariant",
			title: "CTA variant (bottom)",
			columns: 3,
			options: [
				{
					value: "button1",
					img: "/thumbnails/components/Link/button1@2x.png",
				},
				{
					value: "button2",
					img: "/thumbnails/components/Link/button2@2x.png",
				},
				{
					value: "button3",
					img: "/thumbnails/components/Link/button3@2x.png",
				},
				{
					value: "button4",
					img: "/thumbnails/components/Link/button4@2x.png",
				},
			],
		},
		{
			type: "FieldGroup",
			title: "Form fields",
			key: "form",
			fields: [
				{
					type: "TextField",
					key: "openLabel",
					title: "Label for the form opener button",
					humanReadable: true,
				},
				{
					type: "NoteField",
					title: "",
					key: "comparatorNote",
					value: {
						title: "",
						text: "The fields below will be common to all selected programs in comparator view form.",
					},
				},
				{
					...heading,
					key: "formTitle",
					isMockup: true,
					mandatory: false,
				},
				{ ...detail },
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
					advanced: true,
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ContactInfo",
				"FeaturedBlock",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "ProgramComparator",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroInner.default }],
		},
		noResultsTitle: "No has seleccionado ningún programa",
		noResultsText:
			"Selecciona al menos 2 programas para realizar una comparativa",
		itemsPerPage: 6,
		activePage: 1,
		data: {
			sources: [{ structuredData: "PROGRAM" }],
			mode: "auto",
			order: "alpha-ASC",
		},
		bottomCtaText: "Compara estos programas",
		bottomCtaVariant: "button3",
		openLabel: "Pedir más información",
		formTitle: { content: "¿Tienes dudas?", tag: "h3" },
		detail:
			"Si necesitas más información puedes rellenar el siguiente formulario y recibirás información de este programa.",
		legalConditionPreffix:
			"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
		label: "Más información",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramComparator/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramComparator/thumbnail@2x.png",
	},
};

export default schema;

import { AdHocFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	ctaLabel,
	extraFields,
	extraFieldsNote,
	previousLead,
	requestChannelFields,
	utmFields,
} from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<AdHocFormProps> = {
	schemaType: "formTemplate",
	displayName: "Ad Hoc Form",
	component: "AdHocForm",

	content: [
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			title: "Download Label",
			key: "downloadLabel",
			humanReadable: true,
			hideable: true,
		},
		{
			key: "document",
			title: "Document",
			type: "FileField",
			placeholder: "Upload a document",
			hideable: true,
		},
		{
			type: "TextField",
			title: "Title (1st step)",
			key: "title1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "TextField",
			title: "CTA Label (1st step)",
			key: "ctaLabel1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (1st step)",
			key: "formFields1",
			whiteList: [
				"AcademicAreaSelector",
				"CheckboxField",
				"EmailField",
				"EntryDatesSelector",
				"HiddenField",
				"LegalField",
				"NumberField",
				"PasswordField",
				"PhoneField",
				"ProgramSelector",
				"ProgramTypeSelector",
				"RadioButtonField",
				"SectionText",
				"SectionTitle",
				"SelectorField",
				"TextareaField",
				"TextField",
			],
		},
		{
			type: "TextField",
			title: "Title (2nd step)",
			key: "title2",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (2nd step)",
			key: "formFields2",
			whiteList: [
				"AcademicAreaSelector",
				"CheckboxField",
				"EmailField",
				"EntryDatesSelector",
				"HiddenField",
				"LegalField",
				"NumberField",
				"PasswordField",
				"PhoneField",
				"ProgramSelector",
				"ProgramTypeSelector",
				"RadioButtonField",
				"SectionText",
				"SectionTitle",
				"SelectorField",
				"TextareaField",
				"TextField",
			],
		},
		{
			type: "ConditionalField",
			title: "CRM request type",
			key: "requestType",
			options: [
				{
					value: "information",
					name: "information",
					title: "Information (path: com_informationrequests)",
				},
				{ value: "lead", name: "lead", title: "Lead (path: leads)" },
				{
					value: "contact",
					name: "contact",
					title: "Contact (path: contacts)",
				},
				{
					value: "contactAndLead",
					name: "contactAndLead",
					title: "Contact and lead (paths: contacts and leads)",
				},
				{ value: "other", name: "other", title: "Other" },
			],
			fields: [
				{
					condition: "information",
					...previousLead,
				},
				{
					condition: "lead",
					type: "UniqueCheck",
					title: "Add request date",
					key: "addLeadRequestDate",
					options: [
						{
							title: "Add com_fechaultimapeticion if updating lead info.",
						},
					],
				},
				{
					condition: "lead",
					type: "TextField",
					title: "com_gestionado value",
					key: "com_gestionado",
					helptext: "com_gestionado value to send if updating lead info.",
				},
				{
					condition: "contactAndLead",
					type: "FieldGroup",
					title: "Extra fields",
					key: "extraFieldsContactLead",
					fields: [
						{
							type: "TextField",
							title: "Request Channel CRM key",
							key: "requestChannelContactLeadCrmKey",
							helptext: "For example: leadsourcecode.",
						},
						{
							type: "TextField",
							title: "Request channel value (contact)",
							key: "requestChannelContact",
						},
						{
							type: "TextField",
							title: "Request channel value (lead)",
							key: "requestChannelLead",
						},
						{
							type: "TextField",
							title: "Contact source CRM key",
							key: "contactSourceCrmKey",
							helptext: "For example: com_origendelcontacto.",
						},
						{
							type: "TextField",
							title: "Contact source value",
							key: "contactSource",
						},
					],
				},
				{
					condition: "contactAndLead",
					type: "UniqueCheck",
					title: "Add request date",
					key: "addRequestDate",
					options: [
						{
							title: "Add com_fechaultimapeticion if updating lead info.",
						},
					],
				},
				{
					condition: "contactAndLead",
					type: "TextField",
					title: "com_gestionado value",
					key: "com_gestionado",
					helptext: "com_gestionado value to send if updating lead info.",
				},
				{
					condition: "contactAndLead",
					type: "UniqueCheck",
					title: "UTM fields",
					key: "sendUtmFields",
					options: [
						{
							title: "Send UTM fields in 'contact' request.",
						},
					],
				},
				{
					condition: "contactAndLead",
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					title: "Changes in CRM key between 'contact' and 'lead'",
					key: "changesCrmKey",
					name: "Change",
					fields: [
						{
							type: "TextField",
							title: "Contact CRM key",
							key: "contactKey",
							mandatory: true,
						},
						{
							type: "TextField",
							title: "Lead CRM key",
							key: "leadKey",
							helptext:
								"Leave empty if value must be deleted in 'lead' request.",
						},
					],
				},
				{
					condition: "other",
					type: "TextField",
					title: "CRM path",
					key: "crmPath",
					mandatory: true,
				},
				{
					condition: "other",
					...previousLead,
				},
			],
			mandatory: true,
		},
		{ ...extraFieldsNote },
		{ ...extraFields },
		{
			type: "FieldGroup",
			title: "Request channel",
			key: "requestChannel",
			fields: [...requestChannelFields],
		},
		{
			type: "FieldGroup",
			title: "Extra fields (program)",
			key: "extraProgramFields",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "programFieldsNote",
					value: {
						title: "",
						text: "Additional fields when form is in a program page",
					},
				},
				{
					type: "ArrayFieldGroup",
					arrayType: "inline",
					title: "Program fields",
					key: "programFields",
					name: "Program field",
					fields: [
						{
							...crmKey,
							helptext:
								"For example: com_programofinterest, com_codigopublicidad, etc.",
							isTitle: true,
						},
						{
							type: "Select",
							title: "Value",
							key: "value",
							options: [
								{ label: "Title", value: "title" },
								{ label: "ID", value: "idAPI" },
							],
							mandatory: true,
						},
					],
				},
			],
		},
		{
			type: "UniqueCheck",
			title: "Add request date",
			key: "addRequestDate",
			options: [
				{
					title: "Add com_datetimerequest with request date and time.",
				},
			],
		},
		{ ...utmFields },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "AdHocForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		ctaLabel: "Solicitar más información",
		formFields1: [],
		formFields2: [],
		requestType: "information",
		utmFields: [
			{ crmKey: "com_utm_medium", paramName: "utm_medium" },
			{ crmKey: "com_utm_source", paramName: "utm_source" },
			{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
		],
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/forms/AdHocForm/onexed@1x.png",
			"2x": "/thumbnails/forms/AdHocForm/onexed@2x.png",
		},
		"1x": "/thumbnails/forms/AdHocForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/AdHocForm/thumbnail@2x.png",
	},
};

export default schema;

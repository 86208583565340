import { HiddenFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, dependsOn } from "@schemas/forms/presets";

const schema: Schema.FormField<HiddenFieldProps> = {
	schemaType: "formComponent",
	component: "HiddenField",
	displayName: "Hidden Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{
					type: "TextField",
					title: "Value",
					key: "value",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...dependsOn }],
		},
	],

	default: {
		component: "HiddenField",
	},
};

export default schema;

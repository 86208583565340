/* eslint-disable import/order */
import { Core } from "@griddo/core";

// ui/objects schemas
import templates from "./src/schemas/templates";
import objects from "./src/schemas/objects";
import components from "./src/schemas/components";
import modules from "./src/schemas/modules";

// ui/form schemas
import { formFields } from "./src/schemas/forms/components";
import { formTemplates } from "./src/schemas/forms/templates";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categories,
	pageContentType,
	simpleContentType,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacksCategories, dataPacks } from "./src/schemas/data-packs";

export default {
	schemas: {
		config: {
			menus,
			themes,
			languages,
			menuItems,
			moduleCategories,
		},

		ui: {
			components: { ...components, ...objects },
			modules,
			templates,
		},

		contentTypes: {
			dataPacks,
			dataPacksCategories,
			structuredData: {
				...categories,
				...pageContentType,
				...simpleContentType,
			},
		},

		forms: {
			templates: formTemplates,
			fields: formFields,
			categories: [],
		},
	},
} satisfies Core.Config;

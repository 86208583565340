import { EventCallsSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { fieldWidth, helptext, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<EventCallsSelectorProps> = {
	schemaType: "formComponent",
	component: "EventCallsSelector",
	displayName: "Event Calls Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "TextField",
					title: "Field key",
					key: "crmKey",
					mandatory: true,
				},
				{ ...label },
				{ ...helptext },
				{ ...mandatory },
				{
					type: "ArrayFieldGroup",
					arrayType: "inline",
					title: "Options",
					key: "options",
					name: "Option",
					fields: [
						{
							type: "TextField",
							title: "Call ID",
							key: "callId",
							mandatory: true,
						},
						{
							type: "TextField",
							title: "Text",
							key: "text",
							mandatory: true,
							humanReadable: true,
						},
					],
					mandatory: true,
				},
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "EventCallsSelector",
		selectionType: "text",
		options: [
			{ callId: "", text: "Presencial" },
			{ callId: "", text: "Online" },
		],
		fieldWidth: "100",
	},
};

export default schema;

import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="inea-theme"]',
	id: "inea-theme",
	name: "INEA theme",
	primitives: [],
};

export default theme;

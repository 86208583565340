import { MainHeroTvProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	themeSelector,
	animation,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<MainHeroTvProps> = {
	schemaType: "module",
	component: "MainHeroTv",
	category: "heros",
	displayName: "Main Hero TV",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "VIDEO" }],
					key: "data",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{ ...animation },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	thumbnails: {
		"1x": "/thumbnails/modules/MainHeroTv/thumbnail@1x.png",
		"2x": "/thumbnails/modules/MainHeroTv/thumbnail@2x.png",
	},

	default: {
		component: "MainHeroTv",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "VIDEO" }],
			quantity: 3,
			fields: ["image", "duration", "title", "description", "url"],
		},
		anchorID: null,
		subtheme: "inverse",
		animation: "fadeIn",
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},
};

export default schema;

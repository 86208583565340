import { PrinciplesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import PrinciplesCard from "@schemas/components/PrinciplesCard";
import {
	additional,
	anchorID,
	animation,
	content,
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<PrinciplesProps> = {
	schemaType: "module",
	component: "Principles",
	category: "collection",
	displayName: "Principles",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ConditionalField",
					title: "Intro Type",
					key: "introType",
					mandatory: true,
					options: [
						{
							name: "defaultIntro",
							value: "defaultIntro",
							title: "Default Intro",
						},

						{
							name: "institutionalIntro",
							value: "institutionalIntro",
							title: "Institutional Intro",
						},
					],
					fields: [
						{
							condition: "defaultIntro",
							...additional,
						},
						{
							condition: "defaultIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},

						{
							condition: "defaultIntro",
							...detail,
						},
						{
							condition: "defaultIntro",
							...content,
						},
						{
							condition: "institutionalIntro",
							title: "Logo",
							type: "ConditionalField",
							key: "currentLogo",
							hideable: true,
							options: [
								{
									value: "defaultLogo",
									title: "Default",
									name: "defaultLogo",
								},
								{
									value: "customLogo",
									title: "Custom",
									name: "customLogo",
								},
							],
							fields: [
								{
									condition: "defaultLogo",
									title: "Select Logo",
									type: "Select",
									key: "defaultLogo",
									placeholder: "Select a logo",
									mandatory: true,
									options: [
										{ value: "logoComillasHorizontalBn", label: "Comillas" },
										{
											value: "logoComillasHorizontal",
											label: "Comillas color",
										},
										{
											value: "logoComillasHorizontalInverse",
											label: "Comillas inverse",
										},
										{ value: "logoCihsHorizontalBn", label: "CIHS" },
										{ value: "logoCihsHorizontal", label: "CIHS color" },
										{
											value: "logoCihsHorizontalInverse",
											label: "CIHS inverse",
										},
										{ value: "logoEuefHorizontalBn", label: "EUEF" },
										{ value: "logoEuefHorizontal", label: "EUEF color" },
										{
											value: "logoEuefHorizontalInverse",
											label: "EUEF inverse",
										},
										{ value: "logoIcadeHorizontalBn", label: "ICADE " },
										{ value: "logoIcadeHorizontal", label: "ICADE color" },
										{
											value: "logoIcadeHorizontalInverse",
											label: "ICADE inverse",
										},
										{ value: "logoIcaiHorizontalBn", label: "ICAI" },
										{ value: "logoIcaiHorizontal", label: "ICAI color" },
										{
											value: "logoIcaiHorizontalInverse",
											label: "ICAI inverse",
										},
										{ value: "logoIneaBn", label: "INEA" },
										{ value: "logoInea", label: "INEA color" },
										{ value: "logoIneaInverse", label: "INEA inverse" },
									],
								},
								{
									condition: "customLogo",
									title: "Image",
									type: "ImageField",
									key: "customLogo",
									mandatory: true,
									helptext: "Size recommendation: 276x90px",
								},
							],
						},
						{
							condition: "institutionalIntro",
							...additional,
						},
						{
							condition: "institutionalIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "institutionalIntro",
							...content,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["PrinciplesCard"],
					contentType: "components",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "Principles",
		introType: "defaultIntro",
		additional: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: loremIpsumParagraph,
		content: loremIpsumParagraph,
		currentLogo: "defaultLogo",
		defaultLogo: "logoComillasHorizontalBn",
		elements: [
			{ ...PrinciplesCard.default },
			{ ...PrinciplesCard.default },
			{ ...PrinciplesCard.default },
		],
		subtheme: "default",
		anchorID: null,
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Principles/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Principles/thumbnail@2x.png",
	},
};

export default schema;

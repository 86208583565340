import { AgreementsMapProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	emptyState,
	heading,
	loremIpsumParagraph,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Module<AgreementsMapProps> = {
	schemaType: "module",
	component: "AgreementsMap",
	category: "interactive",
	displayName: "Agreements Map",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle, isMockup: false },
				{ ...content, isMockup: false },
				{ ...emptyState },
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "AGREEMENTS" }],
					key: "data",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...animation }],
		},
	],

	default: {
		component: "AgreementsMap",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: null,
		emptyState:
			"Parece que por el momento no hay convenios... ¡Pero seguro que pronto los habrá!",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha-ASC",
			sources: [{ structuredData: "AGREEMENTS" }],
			fields: [
				"title",
				"university",
				"school",
				"academicYear",
				"incoming",
				"outgoing",
				"city",
				"region",
				"country",
				"latitude",
				"longitude",
				"type",
			],
		},
		anchorID: null,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/AgreementsMap/thumbnail@1x.png",
		"2x": "/thumbnails/modules/AgreementsMap/thumbnail@2x.png",
	},
};

export default schema;

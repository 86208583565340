import { CypherCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import CypherCard from "@schemas/components/CypherCard";
import {
	anchorID,
	heading,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
	additional,
	subtitle,
	content,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<CypherCollectionProps> = {
	schemaType: "module",
	component: "CypherCollection",
	category: "collection",
	displayName: "Cypher Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, mandatory: false },
				{ ...subtitle, isMockup: false },
				{ ...content, isMockup: false },
				{
					type: "ComponentArray",
					title: "Cyphers",
					key: "cyphers",
					mandatory: true,
					whiteList: ["CypherCard"],
					contentType: "components",
					maxItems: 4,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CypherCollection",
		additional: "",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "Lorem ipsum subtitle",
		content: loremIpsumParagraph,
		cyphers: [
			{ ...CypherCard.default },
			{ ...CypherCard.default },
			{ ...CypherCard.default },
		],
		subtheme: "default",
		verticalSpacing: "medium",
		anchorID: null,
		animation: "none",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/CypherCollection/onexed@1x.png",
			"2x": "/thumbnails/modules/CypherCollection/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/CypherCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CypherCollection/thumbnail@2x.png",
	},
};

export default schema;

import { TestimonialDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BackgroundImage from "@schemas/components/BackgroundImage";
import {
	additional,
	anchorID,
	animation,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TestimonialDistributorProps> = {
	component: "TestimonialDistributor",
	schemaType: "module",
	category: "distributors",
	displayName: "Testimonial Distributor",
	dataPacks: ["TESTIMONIAL"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		ProgramDetail: ["mainSection"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{
					title: "Background image",
					type: "ComponentContainer",
					key: "backgroundImage",
					whiteList: ["BackgroundImage"],
					helptext: "Recommended minimum image size: 2508x1030px",
					mandatory: true,
				},
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "TESTIMONIAL" }],
					key: "data",
					maxItems: 8,
					mandatory: true,
				},
				{
					type: "UniqueCheck",
					title: "Show School",
					key: "showSchool",
					options: [
						{
							title: "Show School",
						},
					],
					mandatory: true,
				},
				{
					type: "UniqueCheck",
					title: "Show Program",
					key: "showProgram",
					options: [
						{
							title: "Show Program",
						},
					],
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							img: "/thumbnails/modules/TestimonialDistributor/Layouts/layout01.png",
							value: "layout01",
						},
						{
							img: "/thumbnails/modules/TestimonialDistributor/Layouts/layout02.png",
							value: "layout02",
						},
					],
				},
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{ ...verticalSpacing },
				{ ...animation },
				{
					title: "People images",
					type: "RadioGroup",
					key: "peopleImages",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "TestimonialDistributor",
		anchorID: null,
		layout: "layout02",
		subtheme: "default",
		verticalSpacing: "large",
		additional: null,
		title: { tag: "h2", content: "Lorem ipsum" },
		subtitle: loremIpsumParagraph,
		backgroundImage: {
			...BackgroundImage.default,
		},
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "TESTIMONIAL", globalOperator: "OR" }],
			quantity: 6,
			fullRelations: true,
			fields: [
				"photo",
				"position",
				"authorName",
				"quote",
				"centers",
				"programs",
			],
		},
		showSchool: true,
		showProgram: true,
		link: {
			component: "Link",
		},
		animation: "none",
		peopleImages: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TestimonialDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TestimonialDistributor/thumbnail@2x.png",
	},
};

export default schema;

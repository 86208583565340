import { LegalFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.FormField<LegalFieldProps> = {
	schemaType: "formComponent",
	component: "LegalField",
	displayName: "Legal Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
					advanced: true,
				},
			],
		},
	],

	default: {
		component: "LegalField",
		legalConditionPreffix:
			"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
	},
};

export default schema;

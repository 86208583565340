import { Schema } from "@griddo/core";

export const ACADEMIC_AREA: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL", "THESIS"],
	title: "Academic Area",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "idAPI",
				title: "API ID",
				helptext: "Value of parameter areaId returned by the API.",
				type: "TextField",
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "programTypes",
				title: "Program type",
				placeholder: "Program type",
				type: "MultiCheckSelect",
				source: "PROGRAM_TYPE",
			},
		],
	},
};

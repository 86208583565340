import { UIFormFields } from "@griddo/core";

export const dependsOn: UIFormFields.ArrayFieldGroup = {
	type: "ArrayFieldGroup",
	arrayType: "inline",
	title: "Depends on",
	key: "dependsOn",
	name: "",
	fields: [
		{
			type: "TextField",
			title: "Field (key)",
			key: "field",
			mandatory: true,
		},
		{
			type: "TextField",
			title: "Value",
			key: "value",
			mandatory: true,
		},
	],
	hideable: true,
};

import { Schema } from "@griddo/core";

// Lets TypeScript do them type inferring for the `themes` to extract theme
// values as union type. Otherwise is kind of impossible due to the explicit
// Type `Schema.Themes`

const themes: Schema.Themes = [
	{
		default: true,
		label: "Main theme",
		value: "main-theme",
	},
	{
		label: "ICADE",
		value: "icade-theme",
	},
	{
		label: "ICAI",
		value: "icai-theme",
	},
	{
		label: "INEA",
		value: "inea-theme",
	},
	{
		label: "EUEF",
		value: "euef-theme",
	},
	{
		label: "CIHS",
		value: "cihs-theme",
	},
	{
		label: "Onexed",
		value: "onexed-theme",
		elements: {
			include: {
				datapacks: ["LECTURERS", "PROGRAMS", "EVENTS", "NEWS", "THESIS"],
				modules: [
					"MainHero",
					"HeroCarousel",
					"HeroInner",
					"HeroVideo",
					"BasicContent",
					"BasicText",
					"ImageBanner",
					"StickyTabs",
					"ProgramNavigation",
					"VerticalTabs",
					"StudyPlan",
					"StudyPlanAuto",
					"TextCarousel",
					"PeopleDistributor",
					"InnerPeopleDistributor",
					"ProgramsDistributor",
					"InnerProgramsDistributor",
					"ThesisDistributor",
					"ProgramIntroForm",
					"Quote",
					"InnerWidget",
					"ModalForm",
					"EventsDistributor",
					"NewsPinnedDistributor",
					"CardCollection",
					"CypherCollection",
					"LogoCollection",
				],
				templates: [
					"ProgramDetail",
					"ProgramsList",
					"ThesisDetail",
					"ThesisList",
				],
			},
		},
	},
];

const schema = themes;

export { themes };
export default schema;

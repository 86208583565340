import { Schema } from "@griddo/core";

const schema: Schema.Translations = {
	en_GB: {
		global: {
			contact: "Contact",
			readMore: "Read more",
			viewMore: "View more",
			viewLess: "View less",
			seeMore: "See more",
			viewAll: "View all",
			read: "Read",
			hour: "Hour",
			minute: "Minute",
		},
		fields: {
			searchPlaceholder: "Search for programs, school...",
			searchPlaceholderHeader: "Search for programs, events, centers...",
			searchPlaceholderHeaderTV: "Search all media files...",
			searchHelpText: "Type here and press enter",
			explore: "Explore",
			name: "Name",
			lastName: "Last name",
			email: "Email",
			phone: "Phone",
			postalCode: "Postal code",
			programType: "What kind of studies are you interested in?",
			academicArea: "What academic areas are you interested in?",
			user: "User",
			password: "Password",
			contactForm: "Preferred contact method",
			town: "Town",
			select: "Select one option",
			school: "School",
			academicCourse: "Academic course",
			status: "Select your status",
			student: "Student",
			alumni: "Alumni",
			parents: "Parent",
			lecturer: "Counselor/teacher",
			other: "Other",
			yes: "Yes",
			no: "No",
			estimatedAdmissionDate: "Planned entry date",
			program: "Program",
			otherProgram: "Other studies",
			otherProgramHelp: "Tell us another studies you are interested in.",
			downloadDossier: "Check this box if you want to download our dossier.",
			callYou: "Check this box if you want us to contact you by phone.",
			forget: "Forgot your username/password?",
			academicData: "Academic Data",
			academicDataParent: "Academic Data (your child's school and course)",
			additional: "Additional information",
			meet: "How did you knew about us?",
			meetFamily: "Family",
			meetFormerStudent: "Former Student",
			meetGoogle: "Google",
			meetComillasWeb: "Comillas Web",
			meetFair: "Unitour/other fair",
			meetSchool: "School visits",
			meetOpenDay: "Open day",
			meetOnlineSessions: "On-line Sessions",
			meetPress: "Press",
			meetAlumni: "Alumni association",
			meetCompany: "Company",
			meetSocial: "Social Networks",
			meetEmagister: "E-Magister",
			meetCareer: "Advance your career",
			meetOther: "Write here how",
			meetInternet: "Internet",
			meetOtherHelp:
				"Tell us how you found us if it is not any of the above options.",
			meet1: "Family or friends",
			meet2: "LinkedIn",
			meet3: "Facebook",
			meet4: "Instagram",
			meet5: "Comillas Alumni Newsletter",
			meet6: "Press",
			meet7: "Other media",
			meet8: "Comillas WEB",
			enrollment: "Enrollment",
			enrollmentPreferences: "Enrollment preferences",
			inPerson: "In person",
			online: "Online",
			attendees: "Number of people who will attend the event, including you.",
			attendeesHelp:
				"Remember that the capacity is limited. Out of consideration for other families, we ask you to reduce as much as possible the number of companions who will come with you on the day of the event.",
			comment: "Do you want to write any comments?",
			faculty: "Faculty/School",
			title: "Title",
			promotion: "Promotion (end year)",
			subscribe: "Subscription",
			subscribeLabel:
				"I want to stay informed and receive other communications from the university.",
			currentStatus: "Current studies / Employment status",
			currentJob: "Current studies or position",
			company: "Company/Institution",
			needOptions: "What do you need?",
			description: "Description",
			errors: {
				required: "Field is required",
				pattern: "Invalid value",
				phone: "Invalid phone number. Must contain only numbers, +()-. and x.",
				maxPrograms: "Select up to 3 programs",
				min: "Minimum value is 1",
				max: "Maximum value exceeded",
			},
			discussionActivities:
				"I would like to be informed about the discussion activities",
			artActivities: "I would like to be informed about art activities",
			offCampusActivities:
				"I would like to be informed about off-campus activities",
			certificationsAndRegistrarions: "Certificates and registrations",
			equipmentRental: "Equipment rental",
			proposeAndActivity: "I would like to propose an activity",
			generalQuestions: "General questions",
			programInfo: "Which study do you want information about?",
			interestedPrograms: "Select the program of interest",
		},
		formSteps: {
			personalInformation: "Personal Information",
			enrollmentOptions: "Enrollment Options",
			academicData: "Academic Data",
			furtherInformation: "Further Information",
			next: "Next step",
		},
		statusMessage: {
			thankYou: "Thank you",
			error: "Error",
			successMessage: "Your request has been successfully submitted.",
			errorMessage: "An error has occurred",
			cta: "Submit another request",
			closed: "Registration for this event is closed",
			downloadCTA: "Download file",
			programDownloadCTA: "Download brochure",
			thesisDownloadCTA: "Download the complete report",
		},
		newsDetail: {
			shareNews: "Share this news:",
			latestNews: "Latest news",
		},
		newsletter: {
			button: "Sing up now!",
		},
		newsList: {
			dateAsc: "Date ascendant",
			dateDesc: "Date descendant",
			centers: "Centers",
			schools: "Schools",
			services: "Services",
			topics: "Topics",
			search: "Search",
		},
		programDetail: {
			registrationsOpen: "Open enrollment",
			viewProgram: "View program",
			application: "Application for admission",
			info: "More information",
			askInfo: "Ask for information",
		},
		programsList: {
			area: "Academic area",
			centers: "Centers",
			language: "Language",
			registrations: "Open enrollment",
			layout: "Grid layout",
		},
		eventsList: {
			search: "Search",
			eventType: "Event type",
			location: "Location",
			date: "Date",
			layout: "Calendar layout",
			event: "event",
			events: "events",
			eventOn: "Events on",
		},
		searchResults: {
			search: "You searched for",
			resultsFound: "results found",
			related: "Related",
			view: "You have seen",
			of: "of",
			results: "results",
			more: "Load more results",
			all: "All",
			programs: "Programs",
			lecturers: "Lecturers",
			events: "Events",
			news: "News",
			pressReleases: "Press releases",
			QualityTemplate: "Quality and Prospective Service",
			ProgramDetail: "Program",
			EventDetail: "Event",
			NewsDetail: "News",
			PressReleasesDetail: "Press release",
			Lecturer: "Lecturer",
			noResults: "Sorry, no results were found. Please try a different search.",
			searchInput: "Write here if you want to search again",
			viewAll: "View all",
			other: "Other",
			publications: "Publications",
		},
		SearchResultsTv: {
			resultsFound1: "",
			resultsFound2: "results found",
			noResults: "Sorry, no results were found. Please try a different search.",
			related: "Show related content",
			more: "Load more",
			channelsFound: "Found channels",
			goToChannels: "Go to channels results",
			galleriesFound: "Found galleries",
			goToGalleries: "Go to galleries results",
			showLess: "Show",
			showMore: "Hide",
			keywordFound: "Keyword found in",
			labels: "Labels",
			createdBy: "Created by",
			appearsIn: "Appears in",
		},
		SearchResultsGalleries: {
			resultsFound1: "",
			resultsFound2: "results found",
			noResults: "Sorry, no results were found. Please try a different search.",
			more: "Load more",
			sort: "Sort",
			date: "Creation date",
			alphaAsc: "Alphabetical order: A to Z",
			alphaDesc: "Alphabetical order: Z to A",
			members: "Members and subscribers",
			media: "Media files",
			update: "Update date",
		},
		SearchVideoTags: {
			searchTag: "Search tag",
			files: "files",
			sort: "sort",
			date: "Creation date",
			alphaAsc: "Alphabetical order: A to Z",
			alphaDesc: "Alphabetical order: Z to A",
			members: "Members and subscribers",
			media: "Media files",
			update: "Update date",
			noResults: "Sorry, no results were found. Please try a different search.",
			more: "Load more",
			labels: "Labels",
			by: "By",
		},
		SearchVideoCreator: {
			searchCreator: "Search files from",
			files: "files",
			sort: "sort",
			date: "Creation date",
			alphaAsc: "Alphabetical order: A to Z",
			alphaDesc: "Alphabetical order: Z to A",
			members: "Members and subscribers",
			media: "Media files",
			update: "Update date",
			noResults: "Sorry, no results were found. Please try a different search.",
			more: "Load more",
		},
		ThesisDetail: {
			shareProject: "Share project",
		},
		ThesisList: {
			search: "Search thesis",
			area: "Academic area",
			centers: "Centers",
		},
		VideoDetail: {
			details: "Details",
			share: "Share",
			relatedMedia: "Related media files",
			playerMask: "Player mask",
			shareEmail: "Email",
			shareEmailSubject:
				"A video on Universidad Pontificia Comillas has been shared with you",
			shareEmailText: "Share this media file via email",
			shareInsert: "Insert",
			shareInsertWarning:
				"Taking the embed code will make this media file public to everyone and will override all rights set in MediaSpace.",
			shareLink: "Link to media page",
			playerSize: "Player size",
			startEndTime: "Start and end time",
			startAt: "Start at",
			endAt: "End at",
			appearsIn: "Appears in",
		},
		VideosList: {
			videoCaption: "Caption",
			videoDuration: "Duration",
			videoCreation: "Creation date",
			videoUpdate: "Update date",
			search: "Search in this category",
			layout: "List view",
			file: "media file",
			files: "media files",
			yes: "Available",
			no: "Not available",
			last7: "Last 7 days",
			last30: "Last 30 days",
			["0-10"]: "00:00 - 10:00 min",
			["10-30"]: "10:00 - 30:00 min",
			["30-60"]: "30:00 - 60:00 min",
		},
		ChannelDetail: {
			public: "Public",
			limited: "Limited",
			moderate: "Moderated",
			showDetails: "Show details",
			hideDetails: "Hide details",
			file: "media file",
			files: "media files",
			member: "member",
			members: "members",
			managers: "Managers",
			home: "Home",
			viewAll: "View all",
			appearsIn: "Appears in",
		},
		ChannelsList: {
			search: "Search",
			sort: "Sort",
			date: "Creation date",
			alphaAsc: "Alphabetical order: A to Z",
			alphaDesc: "Alphabetical order: Z to A",
			members: "Members and subscribers",
			media: "Media files",
			update: "Update date",
		},
		ProjectDetail: {
			summary: "Summary",
			members: "Research Team",
			fundingOrganization: "Funding organization",
			partnerOrganization: "Beneficiaries or Partners",
			calls: "Calls",
			start: "Start date",
			end: "End date",
			extension: "Extension date",
			grant: "Grant",
			callLink: "Link to the call funding organization",
		},
		ProjectsList: {
			keyword: "Keywords",
			from: "From",
			to: "To",
		},
		heroVideo: {
			button: "Watch video",
		},
		heroProgram: {
			openRegistrations: "Open call for registration",
			closedRegistrations: "New calls for registration coming soon",
			open: "Open calls for registrations",
			closed: "Apply for admission",
			from: "from",
			to: "to",
			and: "and",
		},
		quickFacts: {
			centers: "Centers",
			languages: "Languages",
			modality: "Modality",
			certifications: "Certification",
			format: "Format",
			stage: "Stage",
			intake: "Starts On",
			date: "Date",
			duration: "Duration",
			schedule: "Schedule",
			places: "Seats",
			locations: "Locations",
			schools: "Schools",
			academicAreas: "Academic areas",
			price: "Price",
			practicesHours: "Practices",
		},
		VideoPlaylist: {
			orderBy: "Order by",
			dateAsc: "Date ascendant",
			dateDesc: "Date descendant",
			selectCat: "Select category",
		},
		Lists: {
			results: "results",
			noResults: "Try again by changing filters or keywords.",
			loading: "Just a few seconds, we are loading the results",
		},
		ListFilter: {
			filter: "Filter",
			apply: "Apply",
			clear: "Clear filters",
		},
		ImageGallery: {
			galleryOf: "Gallery of",
			photographs: "photographs",
			photograph: "photograph",
			of: "of",
		},
		StudyPlan: {
			credits: "Credits",
			others: "Others",
			studyPlan: "Study Plan",
		},
		ColorCollection: {
			copy: "Copy",
		},
		DownloadableItemCard: {
			downloadSvg: "Download SVG",
			downloadPng: "Download PNG",
			download: "Download",
		},
		TypographyPreviewer: {
			preview: "Preview this font in different styles",
			style: "Style",
			regular: "Regular",
			italic: "Italic",
			size: "Size",
			download: "Download font",
			playfairPreviewer: "Type here to preview “Playfair Display” font...",
			opensansPreviewer: "Type here to preview “Open Sans” font...",
			futuraPreviewer: "Type here to preview “Futura” font...",
			helveticaPreviewer: "Type here to preview “Helvética” font...",
		},
		HeaderBrand: {
			menu: "Menu",
			search: "Search...",
			searchTitle: "What are you looking for?",
			searchSubtitle: "Find it in Comillas",
			searchHelpText: "Type here and press enter",
		},
		AgreementsMap: {
			outgoingToggle: "Outgoing",
			layoutToggle: "List view",
			all: "All",
			type: "Agreement type",
			incoming: "spots [INCOMING]",
			outgoing: "spots [OUTGOING]",
			incoming2: "INCOMING",
			outgoing2: "OUTGOING",
		},
		TextHeroCountdown: {
			day: "day",
			days: "days",
			hour: "hour",
			hours: "hours",
			minute: "minute",
			minutes: "minutes",
			and: "and",
			timeLeft: "Time left",
		},
		Countdown: {
			minute: "min",
			minutes: "mins",
		},
		ProgramComparator: {
			placeholder: "Search",
			type: "Program type",
			add: "Add to comparator",
			remove: "Remove from comparator",
			summaryTitle: "Select several programs to compare",
			emptyCard: "Another program",
			compareThese: "Compare these",
			programs: "programs",
			chooseOther: "Choose other programs",
			hideMatches: "Hide matches",
			addPrograms: "Add programs",
			fix: "Fix",
			unfix: "Unfix",
			removeShort: "Remove",
			hours: "hours",
			summary: "Abstract",
			opportunities: "Career opportunities",
			profile: "Profile",
			price: "Price",
			registrationPayment: "Tuition",
			monthlyPayment: "Monthly payment",
			monthlyFees: "Monthly fees",
			totalPrice: "Total",
			viewMore: "View more about this program",
			admission: "Ask for information",
			agreements: "Exchange programs",
			address: "Address",
		},
		a11y: {
			closeSearch: "Close search engine",
			openSearch: "Open search engine",
			closeMenu: "Close menu",
			closeSubmenu: "Close submenu",
			openMenu: "Open menu",
			toggleSubmenu: "Toggle submenu",
			prev: "Previous",
			next: "Next",
			goTo: "Go to",
			homeLink: "Link to homepage",
			linkedin: "LinkedIn",
			readMore: "Read more about",
			download: "Download",
			visit: "Visit",
			closeModal: "Close modal",
			play: "Play video",
			goToVideo: "Go to video number",
			hidePassword: "Hide password",
			showPassword: "Show password",
			contentHide: "Hide content",
			contentShow: "Show content",
			openModal: "Open modal",
			playIcon: "Play icon",
			leftIcon: "Left arrow icon",
			rightIcon: "Right arrow icon",
			openDropdown: "Open dropdown",
			removeFile: "Remove file",
			decreaseInputValue: "Decrease input value",
			increaseInputValue: "Increase input value",
			shopOptions: "Show options",
			hideOptions: "Hide options",
			deleteOption: "Delete option",
			closeIcon: "Close icon",
			fileIcon: "File icon",
			searchIcon: "Search icon",
			menuIcon: "Menu icon",
			moreIcon: "More icon",
			upIcon: "Up arrow icon",
			downIcon: "Down arrow icon",
			emailIcon: "Email icon",
			lessIcon: "Less icon",
			toggleContent: "Toggle content",
			toggleVideos: "Toggle videos",
			reset: "Reset field",
		},
		FeaturedPrices: {
			registration: "Registration",
			monthly: "Monthly",
			during: "During",
			total: "Total",
		},
	},
	es_ES: {
		global: {
			contact: "Contactar",
			readMore: "Leer más",
			viewMore: "Ver más",
			viewLess: "Ver menos",
			seeMore: "Ver más",
			viewAll: "Ver todo",
			read: "Leer",
			hour: "Hora",
			minute: "Minuto",
		},
		fields: {
			searchPlaceholder: "Buscar programas, escuela...",
			searchPlaceholderHeader:
				"Prueba a buscar alguna titulación, un evento, un centro...",
			searchPlaceholderHeaderTV: "Busca todos los archivos multimedia...",
			searchHelpText: "Escribe aquí y pulsa intro",
			explore: "Explorar",
			name: "Nombre",
			lastName: "Apellidos",
			email: "Email",
			phone: "Teléfono",
			postalCode: "Código Postal",
			programType: "¿Qué tipo de estudios te interesan?",
			academicArea: "¿Sobre qué áreas?",
			user: "Usuario",
			password: "Contraseña",
			contactForm: "Forma de contacto preferida",
			town: "Localidad",
			select: "Selecciona una opción",
			school: "Escuela",
			academicCourse: "Curso académico",
			status: "Indica tu situación",
			student: "Estudiante",
			alumni: "Antiguo Alumno",
			parents: "Padre/Madre",
			lecturer: "Orientador/profesor",
			other: "Otro",
			yes: "Sí",
			no: "No",
			estimatedAdmissionDate: "Año de preferencia para comenzar",
			program: "Programa",
			otherProgram: "Otros estudios",
			otherProgramHelp:
				"Indica los estudios que te interesan si no figuran en las opciones anteriores.",
			downloadDossier:
				"Marca esta casilla si deseas descargar nuestro dossier.",
			callYou: "Marca esta casilla si deseas que te llamemos por teléfono.",
			forget: "¿Olvidaste tu usuario y/o contraseña?",
			academicData: "Datos Académicos",
			academicDataParent: "Datos Académicos (colegio y curso de su hijo/a)",
			additional: "Información adicional",
			meet: "¿Cómo nos has conocido?",
			meetFamily: "Familia",
			meetFormerStudent: "Antiguo alumno",
			meetGoogle: "Google",
			meetComillasWeb: "Web Comillas",
			meetFair: "Unitour/otra feria",
			meetSchool: "Centro Educativo (visitas colegio)",
			meetOpenDay: "Jornada Puertas Abiertas",
			meetOnlineSessions: "Sesiones On-line",
			meetPress: "Prensa",
			meetAlumni: "Asociación Alumnos",
			meetCompany: "Empresa",
			meetSocial: "Redes Sociales",
			meetEmagister: "E-Magister",
			meetCareer: "Avanza en tu carrera",
			meetInternet: "Internet",
			meetOther: "Escribe cómo aquí",
			meetOtherHelp:
				"Cuéntanos cómo nos has conocido si no está entre ninguna de las opciones anteriores.",
			meet1: "Familiares o amigos",
			meet2: "LinkedIn",
			meet3: "Facebook",
			meet4: "Instagram",
			meet5: "Newsletter Comillas Alumni",
			meet6: "Prensa",
			meet7: "Otros medios",
			meet8: "WEB Comillas",
			enrollment: "Inscripción",
			enrollmentPreferences: "Indica tus preferencias de inscripción",
			inPerson: "Presencial",
			online: "Online",
			attendees:
				"Indica el número de personas que asistirán al evento, incluido tú.",
			attendeesHelp:
				"Recuerde que el aforo es limitado. Por consideración a otras familias, le rogamos reduzca en lo posible el número de acompañantes que acudirán con usted el día del evento.",
			comment: "¿Deseas escribir alguna observación?",
			faculty: "Facultad/Escuela",
			title: "Titulación",
			promotion: "Promoción (año finalización)",
			subscribe: "Suscripción",
			subscribeLabel:
				"Deseo seguir informado y recibir otras comunicaciones de la universidad.",
			currentStatus: "Estudios actuales / Situación laboral",
			currentJob: "Estudios o puesto actual",
			company: "Empresa/Institución",
			needOptions: "¿Qué necesitas?",
			description: "Descripción",
			errors: {
				required: "Campo obligatorio",
				pattern: "El valor no es correcto",
				phone:
					"Número de teléfono incorrecto. Sólo debe contener números, +()-. y x",
				maxPrograms: "Selecciona hasta 3 programas",
				min: "El valor mínimo es 1",
				max: "Se ha superado el valor máximo",
			},
			discussionActivities: "Quiero informarme sobre actividades de Debate",
			artActivities: "Quiero informarme sobre actividades de las Artes",
			offCampusActivities:
				"Quiero informarme sobre actividades fuera del campus",
			certificationsAndRegistrarions: "Certificados y matriculaciones",
			equipmentRental: "Alquiler de material",
			proposeAndActivity: "Quiero proponer una actividad",
			generalQuestions: "Dudas generales",
			programInfo: "Sobre qué estudio quieres información",
			interestedPrograms: "Selecciona el programa de interés",
		},
		formSteps: {
			personalInformation: "Información Personal",
			enrollmentOptions: "Opciones de Inscripción",
			academicData: "Datos académicos",
			furtherInformation: "Otra Información",
			next: "Siguiente paso",
		},
		statusMessage: {
			thankYou: "Muchas gracias",
			error: "Error",
			successMessage: "Tu solicitud ha sido realizada con éxito.",
			errorMessage: "Se ha producido un error.",
			cta: "Realizar otra solicitud",
			closed: "La inscripción a este evento está cerrada",
			downloadCTA: "Descargar fichero",
			programDownloadCTA: "Descargar programa",
			thesisDownloadCTA: "Descargar tesis",
		},
		newsDetail: {
			shareNews: "Compartir noticia:",
			latestNews: "Últimas noticias",
		},
		newsletter: {
			button: "¡Regístrate ahora!",
		},
		newsList: {
			dateAsc: "Más antiguas primero",
			dateDesc: "Más recientes primero",
			centers: "Facultades y escuelas",
			schools: "Institutos y Cátedras",
			services: "Servicios",
			topics: "Temáticas",
			search: "Busca una noticia",
		},
		programDetail: {
			registrationsOpen: "Inscripciones abiertas",
			viewProgram: "Ver programa",
			application: "Solicitar admisión",
			info: "Más información",
			askInfo: "Solicitar información",
		},
		programsList: {
			area: "Área académica",
			centers: "Centros",
			language: "Idioma",
			registrations: "Inscripciones abiertas",
			layout: "Vista cuadrícula",
		},
		eventsList: {
			search: "Busca un evento",
			eventType: "Tipo de evento",
			location: "Ubicación",
			date: "Fecha",
			layout: "Vista de calendario",
			event: "evento",
			events: "eventos",
			eventOn: "Eventos para el",
		},
		searchResults: {
			search: "Has buscado",
			resultsFound: "resultados encontrados",
			related: "Relacionados",
			view: "Has visto",
			of: "de",
			results: "resultados",
			more: "Cargar más resultados",
			all: "Todo",
			programs: "Estudios",
			lecturers: "Claustro",
			events: "Eventos",
			news: "Noticias",
			pressReleases: "Notas de prensa",
			QualityTemplate: "Servicio de Calidad y Prospectiva",
			ProgramDetail: "Titulación",
			EventDetail: "Evento",
			NewsDetail: "Noticia",
			PressReleasesDetail: "Nota de prensa",
			Lecturer: "Claustro",
			noResults:
				"Lo sentimos, no se han encontrado resultados. Por favor, intenta una búsqueda diferente.",
			searchInput: "Escribe aquí si quieres buscar de nuevo",
			viewAll: "Ver todos los resultados",
			other: "Otros",
			publications: "Publicaciones",
		},
		SearchResultsTv: {
			resultsFound1: "Se encontraron",
			resultsFound2: "resultados",
			noResults:
				"Lo sentimos, no se han encontrado resultados. Por favor, intenta una búsqueda diferente.",
			related: "Ver relacionados",
			more: "Cargar más",
			channelsFound: "Canales encontrados",
			goToChannels: "Ir a resultados de canales",
			galleriesFound: "Galerías encontradas",
			goToGalleries: "Ir a resultados de galerías",
			showLess: "Mostrar menos",
			showMore: "Mostrar más",
			keywordFound: "Palabra clave encontrada en",
			labels: "Etiquetas",
			createdBy: "Creado por",
			appearsIn: "Aparece en",
		},
		SearchResultsGalleries: {
			resultsFound1: "Se encontraron",
			resultsFound2: "resultados",
			noResults:
				"Lo sentimos, no se han encontrado resultados. Por favor, intenta una búsqueda diferente.",
			more: "Cargar más",
			sort: "Ordenar",
			date: "Fecha de creación",
			alphaAsc: "Alfabético: A a la Z",
			alphaDesc: "Alfabético: Z a la A",
			members: "Miembros y suscriptores",
			media: "Recuento de archivos multimedia",
			update: "Fecha de actualización",
		},
		SearchVideoTags: {
			searchTag: "Buscar la etiqueta",
			files: "archivos multimedia",
			sort: "Ordenar",
			date: "Fecha de creación",
			alphaAsc: "Alfabético: A a la Z",
			alphaDesc: "Alfabético: Z a la A",
			members: "Miembros y suscriptores",
			media: "Recuento de archivos multimedia",
			update: "Fecha de actualización",
			noResults:
				"Lo sentimos, no se han encontrado resultados. Por favor, intenta una búsqueda diferente.",
			more: "Cargar más",
			labels: "Etiquetas",
			by: "De",
		},
		SearchVideoCreator: {
			searchCreator: "Buscar archivos multimedia desde",
			files: "archivos multimedia",
			sort: "Ordenar",
			date: "Fecha de creación",
			alphaAsc: "Alfabético: A a la Z",
			alphaDesc: "Alfabético: Z a la A",
			members: "Miembros y suscriptores",
			media: "Recuento de archivos multimedia",
			update: "Fecha de actualización",
			noResults:
				"Lo sentimos, no se han encontrado resultados. Por favor, intenta una búsqueda diferente.",
			more: "Cargar más",
		},
		ThesisDetail: {
			shareProject: "Compartir proyecto",
		},
		ThesisList: {
			search: "Buscar tesis",
			area: "Área académica",
			centers: "Centros",
		},
		VideoDetail: {
			details: "Detalles",
			share: "Compartir",
			relatedMedia: "Archivos multimedia relacionados",
			playerMask: "Máscara del reproductor",
			shareEmail: "Correo electrónico",
			shareEmailSubject:
				"Han compartido contigo un vídeo de Universidad Pontificia Comillas",
			shareEmailText:
				"Compartir este archivo multimedia por correo electrónico",
			shareInsert: "Insertar",
			shareInsertWarning:
				"Si toma el código de inserción hará que este archivo multimedia sea público para todo el mundo y anulará todos los derechos definidos en MediaSpace.",
			shareLink: "Enlazar con página de archivos multimedia",
			playerSize: "Tamaño del reproductor",
			startEndTime: "Hora de inicio y finalización",
			startAt: "Empezar a las",
			endAt: "Finalizar a las",
			appearsIn: "Aparece en",
		},
		VideosList: {
			videoCaption: "Subtítulos",
			videoDuration: "Duración",
			videoCreation: "Fecha de creación",
			videoUpdate: "Fecha de actualización",
			search: "Busca en esta categoría",
			layout: "Vista en listado",
			file: "archivo multimedia",
			files: "archivos multimedia",
			yes: "Disponible",
			no: "No disponibles",
			last7: "Últimos 7 días",
			last30: "Últimos 30 días",
			["0-10"]: "00:00 - 10:00 min",
			["10-30"]: "10:00 - 30:00 min",
			["30-60"]: "30:00 - 60:00 min",
		},
		ChannelDetail: {
			public: "Público",
			limited: "Restringido",
			moderate: "Moderado",
			showDetails: "Mostrar detalles",
			hideDetails: "Ocultar detalles",
			file: "archivo multimedia",
			files: "archivos multimedia",
			member: "miembro",
			members: "miembros",
			managers: "Gestores",
			home: "Home",
			viewAll: "Ver todo",
			appearsIn: "Aparece en",
		},
		ChannelsList: {
			search: "Buscar un canal",
			sort: "Ordenar",
			date: "Fecha de creación",
			alphaAsc: "Alfabético: A a la Z",
			alphaDesc: "Alfabético: Z a la A",
			members: "Miembros y suscriptores",
			media: "Recuento de archivos multimedia",
			update: "Fecha de actualización",
		},
		ProjectDetail: {
			summary: "Resumen",
			members: "Equipo Investigador",
			fundingOrganization: "Entidades financiadoras",
			partnerOrganization: "Beneficiarios o Socios",
			calls: "Convocatorias",
			start: "Fecha de inicio",
			end: "Fecha de fin",
			extension: "Fecha de prórroga",
			grant: "Subvención",
			callLink: "Enlace convocatoria organismo financiador",
		},
		ProjectsList: {
			keyword: "Palabras clave",
			from: "Desde",
			to: "Hasta",
		},
		heroVideo: {
			button: "Ver video",
		},
		heroProgram: {
			openRegistrations: "Convocatorias abiertas",
			closedRegistrations: "Nuevas convocatorias próximamente",
			open: "Convocatorias abiertas",
			closed: "Convocatorias abiertas a partir",
			from: "del",
			to: "al",
			and: "y",
		},
		quickFacts: {
			centers: "Facultad",
			languages: "Idiomas",
			modality: "Modalidad",
			certifications: "Certificación",
			format: "Formato",
			stage: "Intercambio",
			intake: "Fecha de inicio",
			date: "Fecha",
			duration: "Duración",
			schedule: "Horario",
			places: "Plazas",
			locations: "Lugar",
			schools: "Escuelas",
			academicAreas: "Áreas académicas",
			price: "Precio",
			practicesHours: "Prácticas",
		},
		VideoPlaylist: {
			orderBy: "Ordenar por",
			dateAsc: "Fecha ascendente",
			dateDesc: "Fecha descendente",
			selectCat: "Selecciona categoría",
		},
		Lists: {
			results: "resultados encontrados",
			noResults:
				"Intenta modificar los filtros o prueba con otra palabra clave.",
			loading: "Sólo unos segundos, estamos cargando los resultados",
		},
		ListFilter: {
			filter: "Filtrar",
			apply: "Aplicar filtros",
			clear: "Borrar todos los filtros",
		},
		ImageGallery: {
			galleryOf: "Galería de",
			photographs: "fotografías",
			photograph: "fotografía",
			of: "de",
		},
		StudyPlan: {
			credits: "Créditos",
			others: "Otros",
			studyPlan: "Plan de estudios",
		},
		ColorCollection: {
			copy: "Copiar",
		},
		DownloadableItemCard: {
			downloadSvg: "Descargar SVG",
			downloadPng: "Descargar PNG",
			download: "Descargar",
		},
		TypographyPreviewer: {
			preview: "Previsualiza la tipografía en distintos estilos",
			style: "Estilo",
			regular: "Regular",
			italic: "Itálica",
			size: "Tamaño",
			download: "Descarga la tipografía aquí",
			playfairPreviewer:
				"Escribe algo aquí para probar la “Playfair Display”...",
			opensansPreviewer: "Escribe algo aquí para probar la “Open Sans”...",
			futuraPreviewer: "Escribe algo aquí para probar la “Futura”...",
			helveticaPreviewer: "Escribe algo aquí para probar la “Helvética”...",
		},
		HeaderBrand: {
			menu: "Menú",
			search: "Buscar...",
			searchTitle: "¿Qué buscas?",
			searchSubtitle: "Encuéntralo en Comillas",
			searchHelpText: "Escribe aquí y pulsa intro",
		},
		AgreementsMap: {
			outgoingToggle: "Outgoing",
			layoutToggle: "Vista de listado",
			all: "Todos",
			type: "Tipo de acuerdo",
			incoming: "plazas [INCOMING]",
			outgoing: "plazas [OUTGOING]",
			incoming2: "INCOMING",
			outgoing2: "OUTGOING",
		},
		TextHeroCountdown: {
			day: "día",
			days: "días",
			hour: "hora",
			hours: "horas",
			minute: "minuto",
			minutes: "minutos",
			and: "y",
			timeLeft: "Faltan",
		},
		Countdown: {
			minute: "min",
			minutes: "mins",
		},
		ProgramComparator: {
			placeholder: "Buscar un programa",
			type: "Tipo de programa",
			add: "Añadir al comparador",
			remove: "Quitar del comparador",
			summaryTitle: "Selecciona varios programas para comparar",
			emptyCard: "Otro programa",
			compareThese: "Compara estos",
			programs: "programas",
			chooseOther: "Elige otros programas",
			hideMatches: "Omitir coincidencias",
			addPrograms: "Añadir programas",
			fix: "Fijar",
			unfix: "Desfijar",
			removeShort: "Quitar",
			hours: "horas",
			summary: "Resumen",
			opportunities: "Salidas profesionales",
			profile: "Perfil",
			price: "Precio",
			registrationPayment: "Matrícula",
			monthlyPayment: "Mensualidad",
			monthlyFees: "Cuotas",
			totalPrice: "Total",
			viewMore: "Ver más sobre el programa",
			admission: "Pedir más información",
			agreements: "Intercambios",
			address: "Dirección",
		},
		a11y: {
			closeSearch: "Cerrar buscador",
			openSearch: "Abrir buscador",
			closeMenu: "Cerrar menú",
			closeSubmenu: "Cerrar submenú",
			openMenu: "Abrir menú",
			toggleSubmenu: "Abrir / cerrar submenú",
			prev: "Anterior",
			next: "Siguiente",
			goTo: "Ir a",
			homeLink: "Enlace a la página principal",
			linkedin: "LinkedIn de",
			readMore: "Lee más sobre",
			download: "Descargar",
			visit: "Visitar",
			closeModal: "Cerrar ventana modal",
			play: "Reproducir vídeo",
			goToVideo: "Ir a vídeo",
			hidePassword: "Ocultar contraseña",
			showPassword: "Mostrar contraseña",
			contentHide: "Ocultar contenido",
			contentShow: "Mostrar contenido",
			openModal: "Abrir modal",
			playIcon: "Icono de reproducción",
			leftIcon: "Icono de flecha hacia la izquierda",
			rightIcon: "Icono de flecha hacia la derecha",
			openDropdown: "Abrir opciones",
			removeFile: "Quitar fichero",
			decreaseInputValue: "Disminuir el valor",
			increaseInputValue: "Aumentar el valor",
			shopOptions: "Mostrar opciones",
			hideOptions: "Ocultar opciones",
			deleteOption: "Eliminar opción",
			closeIcon: "Icono de cerrar",
			fileIcon: "Icono de fichero",
			searchIcon: "Icono de búsqueda",
			menuIcon: "Icono de menú",
			moreIcon: "Icono de más",
			upIcon: "Icono de flecha hacia arriba",
			downIcon: "Icono de flecha hacia abajo",
			emailIcon: "Icono de email",
			lessIcon: "Icono de menos",
			toggleContent: "Mostrar / ocultar contenido",
			toggleVideos: "Mostrar / ocultar vídeos",
			reset: "Borrar campo",
		},
		FeaturedPrices: {
			registration: "Matrícula",
			monthly: "Mensualidad",
			during: "Durante",
			total: "Total",
		},
	},
};

export default schema;

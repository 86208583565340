import { CustomLandingFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	ctaLabel,
	extraFields,
	extraFieldsNote,
	previousLead,
	utmFields,
} from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomLandingFormProps> = {
	schemaType: "formTemplate",
	displayName: "Landing Form",
	component: "CustomLandingForm",

	content: [
		{
			type: "NoteField",
			title: "Form path",
			key: "note",
			value: {
				title: "",
				text: 'This form is sent to the "com_informationrequests" path in the CRM.',
			},
		},
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			title: "Download Label",
			key: "downloadLabel",
			humanReadable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields",
			key: "formFields",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"LandingProgramTypeSelector",
				"LandingAcademicAreaSelector",
				"LandingProgramSelector",
				"EntryDatesSelector",
				"LegalField",
			],
		},
		{
			...crmKey,
			key: "requestChannelCrmKey",
			mandatory: false,
			helptext: "For example: com_tipoestudio.",
		},
		{
			type: "TextField",
			title: "Value (degrees)",
			key: "requestChannelDegrees",
		},
		{
			type: "TextField",
			title: "Value (other program types)",
			key: "requestChannelOther",
		},
		{ ...extraFieldsNote },
		{ ...extraFields },
		{ ...utmFields },
		{
			type: "TextField",
			key: "crmKeyForCampaignCode",
			title: "CRM key for campaign code",
			helptext:
				"For example: com_codigopublicidad. It will take the value of the Campaign code field from the template.",
		},
		{
			type: "TextField",
			key: "crmKeyForPrograms",
			title: "CRM key for program, programs or areas",
			helptext:
				"For example: com_programofinterest. It will have the name of the selected program, programs, or academic areas depending on the type of landing.",
		},
		{ ...previousLead },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomLandingForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		formFields: [],
		ctaLabel: "Solicitar información",
		downloadLabel: "Descargar documento",
		requestChannelCrmKey: "com_tipoestudio",
		requestChannelDegrees: "181410000",
		requestChannelOther: "181410001",
		addLeadRelation: true,
		crmKeyForCampaignCode: "com_codigopublicidad",
		crmKeyForPrograms: "com_programofinterest",
		subtheme: "default",
		extraFields: [
			{
				crmKey: "com_subject",
				value: "Información Solicitada",
			},
			{
				crmKey: "com_name",
				value: "Landing Publicidad",
			},
			{
				crmKey: "com_requestchannel",
				value: "181410011",
			},
		],
		utmFields: [
			{ crmKey: "com_utm_medium", paramName: "utm_medium" },
			{ crmKey: "com_utm_source", paramName: "utm_source" },
			{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
		],
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomLandingForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomLandingForm/thumbnail@2x.png",
	},
};

export default schema;

import { Schema } from "@griddo/core";

const schema: Schema.MenuItem = {
	link: {
		fields: [
			{
				type: "ToggleField",
				title: "",
				key: "isComparatorLink",
				auxText:
					"Click if it is the link to the study comparator. Applies only to the main page.",
				helptext: "",
				background: true,
			},
			{
				title: "Icon",
				type: "Select",
				key: "icon",
				placeholder: "Select an icon",
				options: [
					{ value: "lock", label: "Lock" },
					{ value: "user2", label: "User" },
					{ value: "add", label: "Add" },
					{ value: "pdf", label: "PDF" },
				],
				helptext: "Applies only to the main site and the TV site.",
			},
			{
				type: "ToggleField",
				title: "",
				key: "special",
				auxText:
					"Check it if the link has a left separator. Applies only to the main site and the TV site.",
				helptext: "",
				background: true,
			},
			{
				title: "Image",
				type: "ImageField",
				key: "image",
				helptext: "Applies only to the Brand site.",
			},
		],
	},

	group: {
		fields: [
			{
				type: "ToggleField",
				title: "",
				key: "isComparatorLink",
				auxText:
					"Click if it is the link to the study comparator. Applies only to the main page.",
				helptext: "",
				background: true,
			},
			{
				title: "Icon",
				type: "Select",
				key: "icon",
				placeholder: "Select an icon",
				options: [
					{ value: "lock", label: "Lock" },
					{ value: "user2", label: "User" },
					{ value: "add", label: "Add" },
					{ value: "pdf", label: "PDF" },
				],
				helptext: "Applies only to the main site and the TV site.",
			},
			{
				type: "ToggleField",
				title: "",
				key: "special",
				auxText:
					"Check it if the link has a left separator. Applies only to the main site and the TV site.",
				helptext: "",
				background: true,
			},
			{
				title: "Image",
				type: "ImageField",
				key: "image",
				helptext: "Applies only to the Brand site.",
			},
		],
	},
};

export default schema;

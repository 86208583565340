import { StudyPlanProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<StudyPlanProps> = {
	component: "StudyPlan",
	schemaType: "module",
	category: "content",
	displayName: "Study Plan",
	dataPacks: ["PROGRAMS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		ProgramDetail: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, hideable: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					type: "ReferenceField",
					key: "data",
					title: "Study Plan",
					sources: [{ structuredData: "STUDY_PLAN" }],
					selectionType: ["manual"],
					maxItems: 1,
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "StudyPlan",
		additional: null,
		title: { tag: "h2", content: "Lorem ipsum" },
		subtitle: null,
		content: null,
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
			fields: ["years"],
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/StudyPlan/onexed@1x.png",
			"2x": "/thumbnails/modules/StudyPlan/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/StudyPlan/thumbnail@1x.png",
		"2x": "/thumbnails/modules/StudyPlan/thumbnail@2x.png",
	},
};

export default schema;

import components from "@ui/components";
import formComponents from "@ui/forms/components";
import formsTemplates from "@ui/forms/templates";
import modules from "@ui/modules";
import templates from "@ui/templates";

import browser from "../builder.browser";
import ssr from "../builder.ssr";
import { SiteProvider } from "../griddo.root";

// Prepare components
const bundle = {
	...components,
	...modules,
	...formsTemplates,
	...formComponents,
};

// Dimensions
const generateAutomaticDimensions = (page) => {
	const page_section =
		page.pageContext?.page?.fullPath?.page.split("/")[1] || "";
	const page_name = page.pageContext?.page?.title;
	const page_type = page.pageContext?.page?.templateId;

	const dimensions = {
		event: "custom_event",
		event_name: "page_view",
		page_section,
		page_name,
		page_type,
		...(page.pageContext?.page?.dimensions?.values || {}),
	};

	if (typeof window !== "undefined" && window.location.hash) {
		dimensions.anchor = window.location.hash.replace("#", "");
	}

	return dimensions;
};

// Final exports
export {
	browser,
	bundle as components,
	generateAutomaticDimensions,
	SiteProvider,
	ssr,
	templates,
	formsTemplates,
};

import { ThesisDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	defaultLink,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ThesisDistributorProps> = {
	schemaType: "module",
	component: "ThesisDistributor",
	category: "distributors",
	displayName: "Thesis Distributor",
	dataPacks: ["THESIS"],
	sectionList: {
		ThesisDetail: ["mainSection"],
		BasicTemplate: ["mainSection"],
		EventDetail: ["mainContent"],
		EventsList: ["relatedContent"],
		NewsDetail: ["relatedContent"],
		NewsList: ["relatedContent"],
		ProgramsList: ["relatedContent"],
		ProgramDetail: ["mainSection"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false },
				{ ...subtitle },
				{ ...content },
				{
					title: "Thesis",
					type: "ReferenceField",
					sources: [
						{
							structuredData: "THESIS",
						},
					],
					key: "data",
					mandatory: true,
				},
				{ ...link, title: "Button", hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"inverse-alt",
					]),
				},
				{ ...animation },
				{ ...verticalSpacing },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/ThesisDistributor/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/ThesisDistributor/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/ThesisDistributor/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/ThesisDistributor/Layouts/layout4.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "ThesisDistributor",
		additional: loremIpsumParagraph,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		hasDistributorData: true,
		data: {
			mode: "manual",
			order: "recent-DESC",
			sources: [
				{
					structuredData: "THESIS",
					fields: ["title", "image", "tag", "subtitle", "content", "url"],
				},
			],
		},
		link: { ...defaultLink, variant: "button2", text: "Ver más" },
		anchorID: null,
		subtheme: "inverse",
		animation: "none",
		verticalSpacing: "medium",
		layout: "layout2",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ThesisDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ThesisDistributor/thumbnail@2x.png",
	},
};

export default schema;

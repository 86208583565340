import { UIFormFields } from "@griddo/core";

export const previousLead: UIFormFields.UniqueCheck = {
	type: "UniqueCheck",
	title: "Add previous lead relation",
	key: "addLeadRelation",
	options: [
		{
			title:
				"Add com_estudainatepotencialid@odata.bind if there is a previous lead with user email",
		},
	],
};

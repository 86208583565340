import { FeaturedPricesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getLinkModal,
	getThemeOptions,
	heading,
	linkModal,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<FeaturedPricesProps> = {
	schemaType: "module",
	component: "FeaturedPrices",
	category: "featured",
	displayName: "Featured Prices",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "NoteField",
					key: "note",
					title: "",
					helptext: "",
					value: {
						title: "",
						text: "This module displays prices filled in Program template.",
					},
				},
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{ ...linkModal, hideable: true },
				{
					title: "Style",
					type: "Select",
					key: "style",
					mandatory: true,
					options: [
						{ value: "framed", label: "Framed" },
						{ value: "backgroundColoured", label: "Background Coloured" },
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "FeaturedPrices",
		additional: null,
		title: null,
		subtitle: null,
		content: null,
		link: getLinkModal("Saber más"),
		style: "framed",
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedPrices/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedPrices/thumbnail@2x.png",
	},
};

export default schema;

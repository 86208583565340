import { UIFields } from "@griddo/core";

export const fieldWidth: UIFields.Select = {
	type: "Select",
	title: "Field width",
	key: "fieldWidth",
	options: [
		{ value: "50", label: "50%" },
		{ value: "100", label: "100%" },
	],
	hideable: true,
};

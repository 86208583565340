import { Schema } from "@griddo/core";

export const PROGRAM_TYPE: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL", "THESIS"],
	title: "Program Type (Auto)",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	editable: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "idAPI",
				title: "API ID",
				helptext: "Value of parameter certificacionId returned by the API.",
				type: "TextField",
			},
		],
	},
};

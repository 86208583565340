import { CustomDownloadFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	ctaLabel,
	extraFields,
	extraFieldsNote,
	previousLead,
	requestChannelFields,
	utmFields,
} from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomDownloadFormProps> = {
	schemaType: "formTemplate",
	displayName: "Download Form",
	component: "CustomDownloadForm",

	content: [
		{
			type: "NoteField",
			title: "Form path",
			key: "note",
			value: {
				title: "",
				text: 'This form is sent to the "com_informationrequests" path in the CRM.',
			},
		},
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			title: "Download Label",
			key: "downloadLabel",
			humanReadable: true,
			mandatory: true,
		},
		{
			key: "document",
			title: "Document",
			type: "FileField",
			placeholder: "Upload a document",
			helptext: "It is not necessary to upload a file in program's forms.",
		},
		{
			type: "FormFieldArray",
			title: "Form fields",
			key: "formFields",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"EntryDatesSelector",
				"LegalField",
			],
		},
		{ ...extraFieldsNote },
		{ ...extraFields },
		{
			type: "FieldGroup",
			title: "Extra fields (program)",
			key: "extraProgramFields",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "programFieldsNote",
					value: {
						title: "",
						text: "Additional fields when form is in a program page",
					},
				},
				...requestChannelFields,
				{
					type: "ArrayFieldGroup",
					arrayType: "inline",
					title: "Program fields",
					key: "programFields",
					name: "Program field",
					fields: [
						{
							...crmKey,
							helptext:
								"For example: com_programofinterest, com_codigopublicidad, etc.",
							isTitle: true,
						},
						{
							type: "Select",
							title: "Value",
							key: "value",
							options: [
								{ label: "Title", value: "title" },
								{ label: "ID", value: "idAPI" },
							],
							mandatory: true,
						},
					],
				},
			],
		},
		{ ...previousLead },
		{ ...utmFields },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomDownloadForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		ctaLabel: "Más información",
		downloadLabel: "Descargar documento",
		formFields: [],
		extraFields: [
			{
				crmKey: "com_subject",
				value: "Información Solicitada",
			},
			{
				crmKey: "com_name",
				value: "Descarga Folleto",
			},
			{
				crmKey: "com_requestchannel",
				value: "181410000",
			},
		],
		requestyChannelCrmKey: "com_requestchannel",
		requestChannelDegrees: "181410000",
		requestChannelOther: "181410005",
		programFields: [
			{
				crmKey: "com_programofinterest",
				value: "title",
			},
			{
				crmKey: "com_codigopublicidad",
				value: "idAPI",
			},
		],
		addLeadRelation: true,
		utmFields: [
			{ crmKey: "com_utm_medium", paramName: "utm_medium" },
			{ crmKey: "com_utm_source", paramName: "utm_source" },
			{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
		],
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomDownloadForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomDownloadForm/thumbnail@2x.png",
	},
};

export default schema;

import { ContactInfoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	getThemeOptions,
	heading,
	subtitle,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<ContactInfoProps> = {
	schemaType: "module",
	component: "ContactInfo",
	category: "content",
	displayName: "Contact Info",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					mandatory: false,
					isMockup: true,
					helptext: "Describe in a few words the concept of company",
				},

				{ ...subtitle },

				{
					title: "Logo",
					type: "ConditionalField",
					key: "currentLogo",
					hideable: true,
					options: [
						{
							value: "defaultLogo",
							title: "Default",
							name: "defaultLogo",
						},
						{
							value: "customLogo",
							title: "Custom",
							name: "customLogo",
						},
					],
					fields: [
						{
							condition: "defaultLogo",
							title: "Select Logo",
							type: "Select",
							key: "defaultLogo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{ value: "logoComillasHorizontal", label: "Comillas color" },
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoInea", label: "INEA color" },
								{ value: "logoIneaBn", label: "INEA" },
							],
						},
						{
							condition: "customLogo",
							title: "Image",
							type: "ImageField",
							key: "customLogo",
							mandatory: true,
							helptext: "Size recommendation: 276x90px",
						},
					],
				},

				{
					type: "ComponentArray",
					title: "Contacts",
					key: "contacts",
					whiteList: ["Address", "Fax", "Mail", "Phone", "WhatsApp"],
					contentType: "components",
				},

				{
					title: "Show Visual Content",
					type: "ConditionalField",
					key: "showVisualContent",
					mandatory: true,
					options: [
						{
							value: "widget",
							title: "Widget",
							name: "widget",
						},
						{
							value: "image",
							title: "Image",
							name: "image",
						},
						{
							value: "none",
							title: "None",
							name: "none",
						},
					],
					fields: [
						{
							condition: "widget",
							title: "Widget",
							type: "TextArea",
							key: "widget",
							mandatory: true,
						},

						{
							condition: "image",
							title: "Image",
							type: "ImageField",
							key: "image",
							mandatory: true,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Social Media",
					key: "socialMedia",
					whiteList: [
						"WhatsApp",
						"Facebook",
						"Twitter",
						"Instagram",
						"Linkedin",
						"TikTok",
					],
					contentType: "components",
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"accent",
						"inverse",
						"inverse-alt",
					]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "ContactInfo",
		title: null,
		currentLogo: "defaultLogo",
		defaultLogo: "logoComillasHorizontalBn",
		contacts: [],
		showVisualContent: "none",
		socialMedia: [],
		anchorID: null,
		subtheme: "default",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ContactInfo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ContactInfo/thumbnail@2x.png",
	},
};

export default schema;

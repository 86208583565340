import { DownloadableLogoCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<DownloadableLogoCardProps> = {
	schemaType: "component",
	component: "DownloadableLogoCard",
	displayName: "Downloadable Logo Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					mandatory: true,
				},
				{
					type: "TextField",
					key: "subtitle",
					title: "Subtitle",
					hideable: true,
				},
				{
					title: "Logo type",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					options: [
						{
							name: "default",
							value: "default",
							title: "Default (project logos)",
						},

						{
							name: "custom",
							value: "custom",
							title: "Custom (upload)",
						},
					],
					fields: [
						{
							condition: "default",
							title: "Select icon",
							type: "Select",
							key: "icon",
							placeholder: "Select an icon",
							options: [
								{ value: "logoComillasHorizontalBn", label: "Comillas" },
								{
									value: "logoComillasHorizontal",
									label: "Comillas color",
								},
								{
									value: "logoComillasHorizontalInverse",
									label: "Comillas inverse",
								},
								{ value: "logoCihsHorizontalBn", label: "CIHS" },
								{ value: "logoCihsHorizontal", label: "CIHS color" },
								{
									value: "logoCihsHorizontalInverse",
									label: "CIHS inverse",
								},
								{ value: "logoEuefHorizontalBn", label: "EUEF" },
								{ value: "logoEuefHorizontal", label: "EUEF color" },
								{
									value: "logoEuefHorizontalInverse",
									label: "EUEF inverse",
								},
								{ value: "logoIcadeHorizontalBn", label: "ICADE " },
								{ value: "logoIcadeHorizontal", label: "ICADE color" },
								{
									value: "logoIcadeHorizontalInverse",
									label: "ICADE inverse",
								},
								{ value: "logoIcaiHorizontalBn", label: "ICAI" },
								{ value: "logoIcaiHorizontal", label: "ICAI color" },
								{
									value: "logoIcaiHorizontalInverse",
									label: "ICAI inverse",
								},
								{ value: "logoIneaBn", label: "INEA" },
								{ value: "logoInea", label: "INEA color" },
								{ value: "logoIneaInverse", label: "INEA INVERSE" },
							],
						},
						{
							condition: "custom",
							title: ".PNG or .JPG Format",
							type: "ImageField",
							key: "iconImage",
							hideable: true,
							helptext:
								"Recommended use a vector or minimum image size: 120x120",
						},
						{
							condition: "custom",
							title: ".SVG Format",
							type: "ImageField",
							key: "iconSvg",
							hideable: true,
							helptext:
								"Recommended use a vector or minimum image size: 120x120",
						},
					],
				},
			],
		},
	],

	default: {
		component: "DownloadableLogoCard",
		title: "Lorem ipsum",
		type: "default",
	},
};

export default schema;

import { Schema } from "@griddo/core";

export const THESIS: Schema.PageContentType = {
	dataPacks: ["THESIS"],
	title: "Thesis",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["ThesisDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
			},
			{
				type: "MultiCheckSelect",
				title: "Centers",
				placeholder: "Centers",
				from: "categories.centers",
				key: "centers",
				source: "CENTER",
			},
			{
				type: "MultiCheckSelect",
				title: "Academic area",
				placeholder: "Academic area",
				from: "categories.academicAreas",
				key: "academicAreas",
				source: "ACADEMIC_AREA",
			},
			{
				type: "TextField",
				title: "Tag",
				from: "tag",
				key: "tag",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Subtitle",
				key: "subtitle",
				from: "subtitle",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Content",
				key: "content",
				from: "content",
				mandatory: true,
			},
		],
	},
};

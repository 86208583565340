import { ChannelDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<ChannelDetailProps> = {
	schemaType: "template",
	component: "ChannelDetail",
	displayName: "Channel (Kaltura)",
	dataPacks: ["VIDEOS"],

	type: {
		label: "Channel",
		value: "channel",
		mode: "detail",
	},

	content: [
		{
			type: "FieldGroup",
			key: "channelData",
			title: "Channel Data",

			fields: [
				{
					type: "Wysiwyg",
					key: "description",
					title: "Description",
					hideable: true,
					disabled: true,
				},
				{
					type: "TextField",
					title: "Channel ID",
					key: "channelId",
					mandatory: true,
					readonly: true,
				},
				{
					type: "TextField",
					title: "Kaltura image",
					key: "kalturaImage",
					readonly: true,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					disabled: true,
				},
				{
					type: "NumberField",
					title: "Media files",
					key: "mediaFiles",
					disabled: true,
				},
				{
					type: "NumberField",
					title: "Members count",
					key: "membersCount",
					disabled: true,
				},
				{
					type: "DateField",
					title: "Date",
					key: "channelDate",
					disabled: true,
				},
				{
					type: "DateField",
					title: "Date (update)",
					key: "channelUpdateDate",
					disabled: true,
				},
				{
					type: "TextField",
					title: "Tags",
					key: "tags",
					readonly: true,
				},
				{
					type: "UniqueCheck",
					key: "publicChannel",
					title: "Public",
					options: [{ title: "Public" }],
					disabled: true,
				},
				{
					type: "UniqueCheck",
					key: "userJoinNotAllowed",
					title: "User join not allowed",
					options: [{ title: "User join not allowed" }],
					disabled: true,
				},
				{
					type: "UniqueCheck",
					key: "moderation",
					title: "Moderation",
					options: [{ title: "Moderation" }],
					disabled: true,
				},
				{
					type: "MultiCheckSelectGroup",
					title: "Select Categories",
					key: "categories",
					filled: true,
					disabled: true,
					elements: [
						{
							placeholder: "Categories",
							key: "channelCategories",
							source: "CATEGORY_VIDEO",
						},
						{
							placeholder: "Tags",
							key: "channelTags",
							source: "VIDEO_TAG",
						},
					],
				},
				{
					type: "TextArea",
					key: "managers",
					title: "Managers",
					disabled: true,
				},
				{
					type: "TextArea",
					key: "videos",
					title: "Videos",
					disabled: true,
				},
				{
					type: "ReferenceField",
					title: "Related Videos",
					key: "data",
					sources: [{ structuredData: "VIDEO" }],
					selectionType: ["manual"],
				},
			],
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
	],

	default: {
		type: "template",
		templateType: "ChannelDetail",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
			fields: [
				"videoPlaylist",
				"id",
				"title",
				"image",
				"duration",
				"plays",
				"url",
			],
		},
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ChannelDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ChannelDetail/thumbnail@2x.png",
	},
};

export default schema;

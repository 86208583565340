import { Theme } from "@griddo/core";

import inverseTheme from "../main-theme/inverse";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="inverse"]',
	id: "inverse",
	name: "The inverse theme",
	primitives: [
		...(inverseTheme.primitives as Array<Theme.Primitive>),

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#506B1B" }],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 4
				{ cssVar: "--bg-link-button-4", value: "#506B1B" },
				{ cssVar: "--bg-link-button-4-hover", value: "#506B1B" },
				{ cssVar: "--bg-link-button-4-active", value: "#506B1B" },
				{ cssVar: "--bg-link-button-4-focus", value: "#506B1B" },
			],
		},
	],
};

export default subtheme;

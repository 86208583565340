import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="accent"]',
	id: "accent",
	name: "The accent theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#ffffff" }],
		},

		{
			id: "support",
			name: "support",
			values: [
				{ cssVar: "--success", value: "#00BD08" },
				{ cssVar: "--error", value: "#E02D38" },
				{ cssVar: "--focus-1", value: "#A62BC6" },
			],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [
				{ cssVar: "--text-1", value: "#ffffff" },
				{ cssVar: "--text-2", value: "#181818" },
				{ cssVar: "--text-3", value: "#ffffff" },
				{ cssVar: "--text-4", value: "#ffffff" },
				{ cssVar: "--text-5", value: "#ffffff" },
				{ cssVar: "--text-6", value: "#ffffff" },
				{ cssVar: "--text-7", value: "#ffffff" },
				{ cssVar: "--text-8", value: "#ffffff" },
				{ cssVar: "--text-disabled", value: "#ABABAB" },
			],
		},

		{
			id: "icon",
			name: "icon",
			values: [
				{ cssVar: "--icon-1", value: "#ffffff" },
				{ cssVar: "--icon-disabled", value: "#606060" },
			],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 1
				{ cssVar: "--bg-button-1-disabled", value: "#A4A4A4" },
				// 2
				{ cssVar: "--bg-button-2-disabled", value: "#A4A4A4" },
				// 3
				{ cssVar: "--bg-button-3-hover", value: "rgba(255, 255, 255, 0.5)" },
				{ cssVar: "--bg-button-3-active", value: "#ffffff" },
				{ cssVar: "--bg-button-3-focus", value: "transparent" },
				{ cssVar: "--bg-button-3-disabled", value: "#A4A4A4" },
				// 4
				{ cssVar: "--bg-link-button-4", value: "#ffffff" },
				{ cssVar: "--bg-link-button-4-hover", value: "#ffffff" },
				{ cssVar: "--bg-link-button-4-active", value: "#ffffff" },
				{ cssVar: "--bg-link-button-4-focus", value: "#ffffff" },
				{ cssVar: "--bg-link-button-4-disabled", value: "#A4A4A4" },
			],
		},

		{
			id: "borderButton",
			name: "borderButton",
			values: [
				// 1
				{ cssVar: "--border-button-1-focus", value: "#A62BC6" },
				{ cssVar: "--border-button-1-disabled", value: "transparent" },
				// 2
				{ cssVar: "--border-button-2", value: "#ffffff" },
				{ cssVar: "--border-button-2-focus", value: "#A62BC6" },
				{ cssVar: "--border-button-2-disabled", value: "transparent" },
				// 3
				{ cssVar: "--border-button-3", value: "#ffffff" },
				{ cssVar: "--border-button-3-hover", value: "#ffffff" },
				{ cssVar: "--border-button-3-focus", value: "#A62BC6" },
				{ cssVar: "--border-button-3-disabled", value: "transparent" },
			],
		},

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1-disabled", value: "#5C5C5C" },
				// 2
				{ cssVar: "--text-button-2-disabled", value: "#5C5C5C" },
				// 3
				{ cssVar: "--text-button-3", value: "#ffffff" },
				{ cssVar: "--text-button-3-hover", value: "#ffffff" },
				{ cssVar: "--text-button-3-focus", value: "#ffffff" },
				{ cssVar: "--text-button-3-disabled", value: "#5C5C5C" },
				// 4
				{ cssVar: "--text-link-button-4", value: "#FFFFFF" },
				{
					cssVar: "--text-link-button-4-hover",
					value: "rgba(255, 255, 255, 0.6)",
				},
				{ cssVar: "--text-link-button-4-focus", value: "#FFFFFF" },
				{
					cssVar: "--text-link-button-4-disabled",
					value: "#ABABAB",
				},
			],
		},

		// -------------------------------------------------------------------------
		// LINKS
		// -------------------------------------------------------------------------

		{
			id: "textLink",
			name: "textLink",
			values: [
				// 1
				{ cssVar: "--text-link-1", value: "#CCCCCC" },
				{ cssVar: "--text-link-1-hover", value: "#DEDEDE" },
				{ cssVar: "--text-link-1-active", value: "#C4C4C4" },
				{ cssVar: "--text-link-1-focus", value: "#ffffff" },
				// Menu
				{ cssVar: "--text-link-menu-active", value: "#000000" },
			],
		},

		// -------------------------------------------------------------------------
		// BORDERS
		// -------------------------------------------------------------------------

		{
			id: "border",
			name: "border",
			values: [{ cssVar: "--border-1", value: "#ffffff" }],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#506B1B" },
				{ cssVar: "--bg-1", value: "#506B1B" },
				{ cssVar: "--bg-1-card-hover", value: "#495A27" },
				{ cssVar: "--bg-2", value: "#181818" },
				{ cssVar: "--bg-3", value: "#181818" },
				{ cssVar: "--bg-4", value: "#ffffff" },
				{ cssVar: "--bg-disabled", value: "#BBBBBB" },
			],
		},
	],
};

export default subtheme;

import { EntryDatesSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<EntryDatesSelectorProps> = {
	schemaType: "formComponent",
	component: "EntryDatesSelector",
	displayName: "Entry Dates Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{
					type: "ReferenceField",
					title: "",
					key: "data",
					sources: [{ structuredData: "ENTRY_DATES" }],
					mandatory: true,
				},
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "EntryDatesSelector",
		// hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha-ASC",
			sources: [{ structuredData: "ENTRY_DATES" }],
		},
		fieldWidth: "100",
	},
};

export default schema;

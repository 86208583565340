import { LandingProgramSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<LandingProgramSelectorProps> = {
	schemaType: "formComponent",
	component: "LandingProgramSelector",
	displayName: "Program Selector",

	configTabs: [
		{
			title: "Content",
			fields: [{ ...label }, { ...mandatory }, { ...fieldWidth }],
		},
	],

	default: {
		component: "LandingProgramSelector",
		fieldWidth: "100",
	},
};

export default schema;

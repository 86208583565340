import { SectionTitleProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { dependsOn } from "@schemas/forms/presets";

const schema: Schema.FormField<SectionTitleProps> = {
	schemaType: "formComponent",
	component: "SectionTitle",
	displayName: "Section Title",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "TextField",
					title: "Title",
					key: "title",
					mandatory: true,
					humanReadable: true,
					isMockup: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...dependsOn }],
		},
	],

	default: {
		component: "SectionTitle",
		title: "Lorem ipsum",
	},
};

export default schema;

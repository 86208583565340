import { WidgetProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getLinkModal,
	heading,
	linkModal,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<WidgetProps> = {
	schemaType: "module",
	component: "Widget",
	category: "content",
	displayName: "Widget",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					title: "Type",
					type: "ConditionalField",
					key: "widgetType",
					mandatory: true,
					options: [
						{
							name: "custom",
							value: "custom",
							title: "Custom code fields",
						},

						{
							name: "iframe",
							value: "iframe",
							title: "Iframe URL (resize)",
						},
					],
					fields: [
						{
							condition: "custom",
							key: "widget01",
							type: "TextArea",
							title: "Widget 01",
							mandatory: true,
							placeholder: "Type a script or an iframe",
							helptext:
								"The code of the script or iframe you need to introduce.",
						},
						{
							condition: "custom",
							key: "widget02",
							type: "TextArea",
							title: "Widget 02",
							placeholder: "Type a script or an iframe",
							helptext:
								"The code of the script or iframe you need to introduce.",
							hideable: true,
						},
						{
							condition: "custom",
							key: "iframeSize",
							type: "NumberField",
							title: "Iframe height size",
							hideable: true,
							helptext:
								"Enter a value in px if you want the iframe to have a fixed size in height",
						},
						{
							condition: "iframe",
							key: "iframeUrl",
							type: "TextField",
							title: "Iframe URL",
							mandatory: true,
							helptext:
								"Remember that the code of the specified page must be prepared to send the necessary information to the containing page to resize the iframe.",
						},
						{
							condition: "iframe",
							type: "UniqueCheck",
							title: "Focus on top when iframe is resized",
							key: "focusOnTop",
							options: [
								{
									title: "Focus on top when iframe is resized",
								},
							],
						},
						{
							condition: "iframe",
							title: "Is admission iframe?",
							type: "ConditionalField",
							key: "isAdmission",
							mandatory: true,
							options: [
								{
									name: "yes",
									value: true,
									title: "Yes",
								},
								{
									name: "no",
									value: false,
									title: "No",
								},
							],
							fields: [
								{
									condition: true,
									key: "admissionProgramUrl",
									type: "TextField",
									title: "Iframe URL (programs)",
									helptext:
										"The iframe URL to load when program parameter is given. Remember that the code of the specified page must be prepared to send the necessary information to the containing page to resize the iframe.",
								},
							],
						},
					],
				},
				{ ...linkModal, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/Widget/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/Widget/Layouts/layout02.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],
	default: {
		component: "Widget",
		additional: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		widgetType: "custom",
		widget01: "",
		widget02: "",
		iframeSize: 0,
		iframeUrl: "",
		focusOnTop: false,
		isAdmission: false,
		link: getLinkModal("Saber más", "button3"),
		anchorID: null,
		layout: "layout02",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Widget/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Widget/thumbnail@2x.png",
	},
};

export default schema;

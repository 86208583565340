import { TextFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	dependsOn,
	fieldWidth,
	helptext,
	label,
	mandatory,
} from "@schemas/forms/presets";

const schema: Schema.FormField<TextFieldProps> = {
	schemaType: "formComponent",
	component: "TextField",
	displayName: "Text Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{ ...helptext },
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},

		{
			title: "config",
			fields: [{ ...dependsOn }],
		},
	],

	default: {
		component: "TextField",
		fieldWidth: "100",
	},
};

export default schema;

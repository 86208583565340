import { LandingAcademicAreaSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<LandingAcademicAreaSelectorProps> = {
	schemaType: "formComponent",
	component: "LandingAcademicAreaSelector",
	displayName: "Academic Area Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "note",
					value: {
						title: "",
						text: "This field options will be filtered by program type selector, if exists.",
					},
				},
				{ ...label },
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "LandingAcademicAreaSelector",
		fieldWidth: "100",
	},
};

export default schema;

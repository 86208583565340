import { UIFormFields } from "@griddo/core";

import { crmKey } from "./crmKey";

export const extraFieldsNote: UIFormFields.NoteField = {
	type: "NoteField",
	title: "Extra fields",
	key: "extraFieldsNote",
	value: {
		title: "",
		text: "Additional fields with fixed values to be sent to CRM",
	},
};

export const extraFields: UIFormFields.ArrayFieldGroup = {
	type: "ArrayFieldGroup",
	arrayType: "inline",
	title: "",
	key: "extraFields",
	name: "Extra field",
	fields: [
		{
			...crmKey,
			helptext: "For example: com_subject, com_name, etc.",
			isTitle: true,
		},
		{
			type: "TextField",
			title: "Value",
			key: "value",
			mandatory: true,
		},
	],
};

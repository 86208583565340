import { InnerPublicationsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	animation,
	getThemeOptions,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<InnerPublicationsDistributorProps> = {
	schemaType: "module",
	component: "InnerPublicationsDistributor",
	displayName: "Publications Distributor",
	dataPacks: ["PUBLICATIONS"],

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "PUBLICATIONS" }],
					key: "data",
					mandatory: true,
					maxItems: 6,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/PublicationsDistributor/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/PublicationsDistributor/Layouts/layout02.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerPublicationsDistributor",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "PUBLICATIONS" }],
			quantity: 6,
			fullRelations: true,
			fields: ["title", "url", "image"],
		},
		link: {
			component: "Link",
		},
		subtheme: "default",
		animation: "none",
		verticalSpacing: "medium",
		layout: "layout01",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/PublicationsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/PublicationsDistributor/thumbnail@2x.png",
	},
};

export default schema;

import { ACADEMIC_AREA } from "./ACADEMIC_AREA";
import { AGREEMENTS } from "./AGREEMENTS";
import { CAMPUS } from "./CAMPUS";
import { CATEGORY_VIDEO } from "./CATEGORY_VIDEO";
import { CENTER } from "./CENTER";
import { CERTIFICATION } from "./CERTIFICATION";
import { DOCUMENT } from "./DOCUMENT";
import { ENTRY_DATES } from "./ENTRY_DATES";
import { EVENT_AUTO } from "./EVENT_AUTO";
import { FUNDING_ORGANIZATION } from "./FUNDING_ORGANIZATION";
import { LANGUAGE } from "./LANGUAGE";
import { LECTURER } from "./LECTURER";
import { PARTNER_ORGANIZATION } from "./PARTNER_ORGANIZATION";
import { PEOPLE } from "./PEOPLE";
import { PLAYLIST } from "./PLAYLIST";
import { PROGRAM_EXTERNAL } from "./PROGRAM_EXTERNAL";
import { PROGRAM_TAG } from "./PROGRAM_TAG";
import { PROGRAM_TYPE } from "./PROGRAM_TYPE";
import { PUBLICATIONS } from "./PUBLICATIONS";
import { RESEARCH_GROUP } from "./RESEARCH_GROUP";
import { SCHOOL } from "./SCHOOL";
import { STUDY_PLAN } from "./STUDY_PLAN";
import { TESTIMONIAL } from "./TESTIMONIAL";

export default {
	ACADEMIC_AREA,
	AGREEMENTS,
	CAMPUS,
	CATEGORY_VIDEO,
	CENTER,
	CERTIFICATION,
	DOCUMENT,
	ENTRY_DATES,
	EVENT_AUTO,
	FUNDING_ORGANIZATION,
	RESEARCH_GROUP,
	LANGUAGE,
	LECTURER,
	PARTNER_ORGANIZATION,
	PEOPLE,
	PLAYLIST,
	PROGRAM_EXTERNAL,
	PROGRAM_TAG,
	PROGRAM_TYPE,
	PUBLICATIONS,
	SCHOOL,
	STUDY_PLAN,
	TESTIMONIAL,
};

import { CustomContactFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	ctaLabel,
	extraFields,
	extraFieldsNote,
	previousLead,
	requestChannelFields,
	utmFields,
} from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomContactFormProps> = {
	schemaType: "formTemplate",
	displayName: "Contact Form",
	component: "CustomContactForm",

	content: [
		{
			type: "NoteField",
			title: "Form path",
			key: "note",
			value: {
				title: "",
				text: 'This form is sent to the "com_informationrequests" path in the CRM.',
			},
		},
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "FormFieldArray",
			title: "Form fields",
			key: "formFields",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"ProgramTypeSelector",
				"AcademicAreaSelector",
				"EntryDatesSelector",
				"LegalField",
			],
		},
		{ ...extraFieldsNote },
		{ ...extraFields },
		{
			type: "FieldGroup",
			title: "Request channel",
			key: "requestChannel",
			fields: [...requestChannelFields],
		},
		{ ...previousLead },
		{ ...utmFields },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomContactForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		ctaLabel: "Solicitar más información",
		formFields: [],
		extraFields: [
			{
				crmKey: "com_subject",
				value: "Información Solicitada",
			},
			{
				crmKey: "com_name",
				value: "Contacto",
			},
		],
		requestyChannelCrmKey: "com_requestchannel",
		requestChannelDegrees: "181410000",
		requestChannelOther: "181410005",
		addLeadRelation: true,
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomContactForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomContactForm/thumbnail@2x.png",
	},
};

export default schema;

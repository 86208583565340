import { CustomAdmissionFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { ctaLabel } from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomAdmissionFormProps> = {
	schemaType: "formTemplate",
	displayName: "Admission Form",
	component: "CustomAdmissionForm",

	content: [
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{
			type: "TextField",
			key: "action",
			title: "Form action",
			mandatory: true,
		},
		{ ...ctaLabel },
		{
			type: "UrlField",
			title: "Forget user/password link",
			key: "forgetLink",
			placeholder: "https://",
			advanced: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields",
			key: "formFields",
			whiteList: ["TextField", "EmailField", "PasswordField"],
			maxItems: 2,
		},
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomAdmissionForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		formFields: [],
		detail: loremIpsumParagraph,
		action:
			"https://web.upcomillas.es/portaldeadmisiones/acceso/missolicitudes.aspx",
		forgetLink: undefined,
		ctaLabel: "Entrar",
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomAdmissionForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomAdmissionForm/thumbnail@2x.png",
	},
};

export default schema;

import { Schema } from "@griddo/core";

export const CAMPUS: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS", "LECTURERS", "TESTIMONIAL"],
	title: "Campus",
	local: false,
	translate: false,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "idAPI",
				title: "API ID",
				helptext: "Value of parameter areaId returned by the API.",
				type: "TextField",
			},
			{
				key: "address",
				title: "Address text",
				helptext: "Displayed in programs comparator.",
				type: "TextField",
			},
			{
				type: "TextField",
				key: "latitude",
				title: "Latitude",
				placeholder: "40.43010660646391",
			},
			{
				type: "TextField",
				key: "longitude",
				title: "Longitude",
				placeholder: "-3.711409670669836",
			},
			{
				key: "divider",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Select Categories",
					text: "",
				},
			},
			{
				key: "center",
				title: "Center",
				placeholder: "Center",
				type: "MultiCheckSelect",
				source: "CENTER",
			},
		],
	},
};

import { PostgraduateEventFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	detail,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Component<PostgraduateEventFormProps> = {
	schemaType: "component",
	displayName: "Postgraduate Event Form",
	component: "PostgraduateEventForm",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},
				{ ...detail },
				{
					type: "TextField",
					key: "eventCode",
					title: "Event code",
					mandatory: true,
				},
				{
					type: "TextField",
					key: "eventId",
					title: "Event ID",
					mandatory: true,
				},
				{
					type: "CheckGroup",
					title: "Select extra fields to display",
					key: "fields",
					options: [
						{
							title: "Alumni",
							name: "alumni",
						},
						{
							title: "Current status",
							name: "currentStatus",
						},
						{
							title: "Interested in our programs",
							name: "programs",
						},
						{
							title: "Subscribe option",
							name: "subscribe",
						},
						{
							title: "How did you meet us?",
							name: "meet",
						},
						{
							title: "Comment",
							name: "comment",
						},
					],
				},
				{
					title: "Programs",
					type: "ReferenceField",
					sources: [
						{ structuredData: "PROGRAM" },
						{ structuredData: "PROGRAM_EXTERNAL" },
					],
					key: "data",
				},
				{
					type: "TextField",
					key: "legalConditionPreffix",
					title: "Legal condition text preffix",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					key: "legalConditionSuffix",
					title: "Legal condition link text",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "UrlField",
					title: "Legal condition link",
					key: "legalConditionLink",
					placeholder: "https://",
				},
				{
					type: "TextField",
					key: "label",
					title: "Button Label",
					placeholder: "Type a label",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "Config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"accent-alt",
						"inverse",
					]),
				},
				{
					title: "Show decoration",
					key: "decoration",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "PostgraduateEventForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		hasDistributorData: true,
		data: {
			mode: "manual",
			fields: ["title"],
		},
		label: "Inscribirme en el evento",
		legalConditionPreffix:
			"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la",
		legalConditionSuffix: "Política de Privacidad",
		legalConditionLink: undefined,
		subtheme: "default",
		decoration: false,
	},
};

export default schema;

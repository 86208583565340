import { ThesisDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ThesisForm from "@schemas/components/ThesisForm";
import BasicContent from "@schemas/modules/BasicContent";
import BasicText from "@schemas/modules/BasicText";
import CardCollection from "@schemas/modules/CardCollection";
import CypherCollection from "@schemas/modules/CypherCollection";
import LogoCollection from "@schemas/modules/LogoCollection";
import MainHero from "@schemas/modules/MainHero";
import PeopleDistributor from "@schemas/modules/PeopleDistributor";
import Quote from "@schemas/modules/Quote";
import StickyTabs from "@schemas/modules/StickyTabs";
import ThesisDistributor from "@schemas/modules/ThesisDistributor";
import {
	additional,
	content,
	heading,
	loremIpsumParagraph,
	subtitle,
} from "@schemas/presets";

const schema: Schema.Template<ThesisDetailProps> = {
	schemaType: "template",
	component: "ThesisDetail",
	displayName: "Thesis",
	dataPacks: ["THESIS"],

	type: {
		label: "Thesis",
		value: "thesis",
		mode: "detail",
	},

	content: [
		{
			type: "ComponentArray",
			title: "Navigation",
			key: "navigation",
			whiteList: ["StickyTabs"],
			contentType: "modules",
			maxItems: 1,
			mandatory: true,
		},
		{
			type: "FieldGroup",
			title: "Distributors and lists fields",
			key: "distributorFields",
			fields: [
				{
					type: "ImageField",
					title: "Image",
					key: "image",
					hideable: true,
					helptext: "This image will be shown in distributors and lists",
				},
				{
					type: "TextField",
					title: "Tag",
					key: "tag",
					hideable: true,
					placeholder: "Type a tag",
					humanReadable: true,
					helptext: "This text will be shown in distributors and lists",
				},
				{
					...subtitle,
					helptext: "This text will be shown in distributors and lists",
				},
				{
					...content,
					helptext: "This text will be shown in distributors and lists",
				},
				{
					type: "MultiCheckSelectGroup",
					title: "Select Categories",
					key: "categories",
					filled: true,
					elements: [
						{
							placeholder: "Centers",
							key: "centers",
							source: "CENTER",
						},
						{
							placeholder: "Academic areas",
							key: "academicAreas",
							source: "ACADEMIC_AREA",
						},
					],
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["MainHero"],
			contentType: "modules",
			key: "heroSection",
		},
		{
			type: "ComponentArray",
			title: "Basic Text",
			key: "basicText",
			whiteList: ["BasicText"],
			maxItems: 1,
			contentType: "modules",
		},
		{
			type: "FieldGroup",
			title: "Intro Form fields",
			key: "introForm",
			fields: [
				{ ...additional },
				{
					...heading,
					title: "Intro title",
					key: "introTitle",
					mandatory: true,
				},
				{
					type: "Wysiwyg",
					full: false,
					title: "Intro content",
					key: "introContent",
					mandatory: true,
					humanReadable: true,
				},
				{
					type: "ComponentContainer",
					title: "Form",
					whiteList: ["ThesisForm"],
					key: "form",
					hideable: true,
				},
				{
					title: "Social Media",
					type: "CheckGroup",
					key: "socialMedia",
					options: [
						{ name: "facebook", title: "Facebook" },
						{ name: "linkedin", title: "LinkedIn" },
						{ name: "twitter", title: "X (Twitter)" },
						{ name: "paperPlane", title: "Email" },
					],
					hideable: true,
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Main Content",
			key: "mainSection",
			whiteList: [
				"Quote",
				"BasicContent",
				"CardCollection",
				"CypherCollection",
				"PeopleDistributor",
				"ThesisDistributor",
				"LogoCollection",
			],
			contentType: "modules",
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "ThesisDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...MainHero.default }],
		},
		basicText: {
			component: "Section",
			name: "Basic Text",
			modules: [
				{ ...BasicText.default, layout: "layout01", verticalSpacing: "small" },
			],
		},
		navigation: {
			component: "Section",
			name: "Navigation",
			modules: [{ ...StickyTabs.default }],
		},
		additional: null,
		introTitle: { content: "", tag: "h2" },
		introContent: loremIpsumParagraph,
		form: {
			...ThesisForm.default,
		},
		socialMedia: ["facebook"],
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...Quote.default },
				{ ...BasicContent.default },
				{ ...CardCollection.default },
				{ ...CypherCollection.default },
				{ ...BasicContent.default },
				{ ...PeopleDistributor.default },
				{ ...ThesisDistributor.default },
				{ ...LogoCollection.default },
			],
		},
		titleSize: "m",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ThesisDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ThesisDetail/thumbnail@2x.png",
	},
};

export default schema;

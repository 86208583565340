import { SelectorFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	dependsOn,
	fieldWidth,
	helptext,
	label,
	mandatory,
} from "@schemas/forms/presets";

const schema: Schema.FormField<SelectorFieldProps> = {
	schemaType: "formComponent",
	component: "SelectorField",
	displayName: "Selector Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{ ...helptext },
				{ ...mandatory },
				{
					type: "Select",
					title: "Selection type",
					key: "selectionType",
					options: [
						{ value: "text", label: "Single" },
						{ value: "checkbox", label: "Multiple" },
					],
					hideable: true,
				},
				{
					type: "TextField",
					title: "Empty option text",
					key: "emptyOption",
					hideable: true,
					humanReadable: true,
				},
				{
					type: "ArrayFieldGroup",
					arrayType: "inline",
					title: "Options",
					key: "options",
					name: "Option",
					fields: [
						{
							type: "TextField",
							title: "Value",
							key: "value",
							mandatory: true,
						},
						{
							type: "TextField",
							title: "Text",
							key: "text",
							mandatory: true,
							humanReadable: true,
						},
					],
				},
				{ ...fieldWidth },
			],
		},

		{
			title: "config",
			fields: [{ ...dependsOn }],
		},
	],

	default: {
		component: "SelectorField",
		selectionType: "text",
		emptyOption: "Selecciona una opción",
		options: [],
		fieldWidth: "100",
	},
};

export default schema;
